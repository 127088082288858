import Icon from '../components/Icon';

const Overflow = Icon(
  'overflow',
  <>
    <path d="M6 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM14 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM22 12a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" />
  </>
);

export default Overflow;
