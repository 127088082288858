import * as React from 'react';
import classNames from 'classnames';
import InfoDividerDot from '@alltrails/shared/components/cards/InfoDividerDot';
import Link from '@alltrails/denali/components/Link';
import StarRating from '@alltrails/denali/components/StarRating';
import { LinkInfo } from '@alltrails/denali/types';
import styles from './EntityLabel.module.scss';

export type Props = {
  avatar: React.ReactNode;
  className?: string;
  link: LinkInfo;
  nameExtension?: React.ReactNode;
  onClick?: () => void;
  secondaryText?: React.ReactNode | { linkInfo?: LinkInfo; onClick?: () => void; text: string };
  socialLink?: JSX.Element;
  tertiaryText?: React.ReactNode | { linkInfo: LinkInfo; onClick?: () => void; text: string };
  testIdPrefix?: string;
  title: string;
  titleClassName?: string;
  privacyElement?: JSX.Element;
  ratingValue?: number;
};

const EntityLabel = ({
  avatar,
  className,
  link,
  nameExtension,
  onClick,
  secondaryText,
  socialLink,
  tertiaryText,
  testIdPrefix,
  title,
  titleClassName,
  privacyElement,
  ratingValue
}: Props): JSX.Element => (
  <div className={classNames(styles.container, className)}>
    {avatar}
    <div className={styles.labelStack}>
      {/* An edge case, but it's possible we have no label at all https://github.com/alltrails/monorepo/pull/1667 */}
      {title && link.href ? (
        <div className={styles.labelRow}>
          <Link
            href={link.href}
            target="_blank"
            rel={link.rel || []}
            className={classNames(styles.title, { [styles.withExtension]: !!nameExtension }, titleClassName)}
            data-testid={`${testIdPrefix}-title`}
            onClick={onClick}
            noUnderline
          >
            <span>{title}</span>
          </Link>
          {!!nameExtension && <div className={styles.extension}>{nameExtension}</div>}
        </div>
      ) : null}
      {secondaryText && (
        <div className={classNames(styles.labelRow, styles.subtitle)}>
          {ratingValue && <StarRating className={styles.rating} rating={ratingValue} />}
          {typeof secondaryText === 'object' && 'text' in secondaryText ? (
            <Link {...secondaryText.linkInfo} size="sm" testId={`${testIdPrefix}-secondary-text`} onClick={secondaryText.onClick}>
              {secondaryText.text}
            </Link>
          ) : (
            <span data-testid={`${testIdPrefix}-secondary-text`}>{secondaryText}</span>
          )}
          {socialLink ? (
            <>
              <InfoDividerDot />
              {socialLink}
            </>
          ) : (
            tertiaryText && (
              <>
                <InfoDividerDot />
                {typeof tertiaryText === 'object' && 'text' in tertiaryText ? (
                  <Link {...tertiaryText.linkInfo} className={styles.tertiaryText} size="sm" testId={`${testIdPrefix}-tertiary-text`}>
                    {tertiaryText.text}
                  </Link>
                ) : (
                  <span data-testid={`${testIdPrefix}-tertiary-text`}>{tertiaryText}</span>
                )}
              </>
            )
          )}
          {privacyElement && (
            <>
              <InfoDividerDot />
              {privacyElement}
            </>
          )}
        </div>
      )}
    </div>
  </div>
);

export default EntityLabel;
