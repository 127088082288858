export default function getPlainText(node: string | JSX.Element | JSX.Element[]) {
  if (typeof node === 'string') {
    return node;
  }

  if (Array.isArray(node)) {
    return node.map(getPlainText).join('');
  }

  if (node?.props?.children) {
    return getPlainText(node.props.children);
  }

  return '';
}
