import { useLocalStorage } from '@alltrails/local-storage';
import { useCallback, useEffect, useState } from 'react';

/**
 * useInstallPrompt determines whether or not to display the prompt.
 * Banners & interstitials can only open if a certain duration of time has passed since they were dismissed.
 * The dismissal time is stored in local storage, and read when attempting to open the component again.
 */

const oneDay = 60 * 60 * 24 * 1000;
const oneWeek = 60 * 60 * 24 * 7 * 1000;

export enum DeepLinkLocalStorageKeys {
  BANNER = 'banner-dismissal-date',
  INTERSTITIAL = 'interstitial-dismissal-date'
}

const bannerData = {
  localStorageKey: DeepLinkLocalStorageKeys.BANNER,
  dismissalDuration: oneDay
};

const interstitialData = {
  localStorageKey: DeepLinkLocalStorageKeys.INTERSTITIAL,
  dismissalDuration: oneWeek
};

const didPassDismissalDuration = (existingDismissalDate: number | null, duration: number) => {
  // Display the prompt if no dismissal date exists yet.
  if (!existingDismissalDate) {
    return true;
  }

  if (existingDismissalDate) {
    const now = new Date().getTime();
    if (now - existingDismissalDate >= duration) {
      return true;
    }
  }

  return false;
};

type DeeplinkHookReturnType = [boolean, (shouldOpenDeeplinkComponent: boolean) => boolean];

const useInstallPrompt = (promptType: 'banner' | 'interstitial', shouldOpenInitial: boolean, overrideSnooze?: boolean): DeeplinkHookReturnType => {
  const [isOpen, setIsOpen] = useState(false);
  const { dismissalDuration, localStorageKey } = promptType === 'interstitial' ? interstitialData : bannerData;
  const { getValue, setValue } = useLocalStorage<number>(localStorageKey);

  // Update the isOpen state, and return the value for synchronous use.
  const setIsOpenAttempt = useCallback(
    (shouldOpen: boolean): boolean => {
      const existingDismissalDate = getValue();
      if (shouldOpen && (overrideSnooze || didPassDismissalDuration(existingDismissalDate, dismissalDuration))) {
        setIsOpen(true);
        return true;
      }

      if (!shouldOpen) {
        if (overrideSnooze) {
          setIsOpen(false);
          return false;
        }

        const dismissalDateTime = new Date().getTime();
        setValue(dismissalDateTime);
        setIsOpen(false);
        return false;
      }

      return false;
    },
    [getValue, overrideSnooze, dismissalDuration, setValue]
  );

  useEffect(() => {
    if (shouldOpenInitial) {
      setIsOpenAttempt(true);
    }
  }, [setIsOpenAttempt, shouldOpenInitial]);

  return [isOpen, setIsOpenAttempt];
};

export default useInstallPrompt;
