import { type IntlShape } from 'react-intl';
import { NationalParksFaqPageFilter } from './NationalParksFaq';

export const getNationalParksQuestionString = (intl: IntlShape, locationName: string, filter?: NationalParksFaqPageFilter) => {
  const messageKey = filter || 'default';

  const translations = {
    default: intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have hiking trails?' }, { locationName }),
    backpacking: intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have backpacking trails?' }, { locationName }),
    'bike-touring': intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have bike touring trails?' }, { locationName }),
    birding: intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have bird watching trails?' }, { locationName }),
    camping: intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have camping trails?' }, { locationName }),
    fishing: intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have fishing trails?' }, { locationName }),
    hiking: intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have hiking trails?' }, { locationName }),
    'horseback-riding': intl.formatMessage(
      { defaultMessage: 'What national parks in {locationName} have horseback riding trails?' },
      { locationName }
    ),
    'mountain-biking': intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have mountain biking trails?' }, { locationName }),
    'off-road-driving': intl.formatMessage(
      { defaultMessage: 'What national parks in {locationName} have off-road driving trails?' },
      { locationName }
    ),
    'road-biking': intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have road biking trails?' }, { locationName }),
    'rock-climbing': intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have rock climbing trails?' }, { locationName }),
    'scenic-driving': intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have scenic driving trails?' }, { locationName }),
    skiing: intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have skiing trails?' }, { locationName }),
    snowshoeing: intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have snowshoeing trails?' }, { locationName }),
    'trail-running': intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have running trails?' }, { locationName }),
    'via-ferrata': intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have via ferrata trails?' }, { locationName }),
    walking: intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have walking trails?' }, { locationName }),
    'cross-country-skiing': intl.formatMessage(
      { defaultMessage: 'What national parks in {locationName} have cross country skiing trails?' },
      { locationName }
    ),
    ada: intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have wheelchair-friendly trails?' }, { locationName }),
    beach: intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have beach trails?' }, { locationName }),
    cave: intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have cave trails?' }, { locationName }),
    dogs: intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have dog-friendly trails?' }, { locationName }),
    forest: intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have forest trails?' }, { locationName }),
    'historic-site': intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have historic site trails?' }, { locationName }),
    'hot-springs': intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have trails with hot springs?' }, { locationName }),
    kids: intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have kid-friendly trails?' }, { locationName }),
    lake: intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have lake trails?' }, { locationName }),
    'partially-paved': intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have partially-paved trails?' }, { locationName }),
    paved: intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have paved trails?' }, { locationName }),
    'rails-trails': intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have rails trails?' }, { locationName }),
    river: intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have river trails?' }, { locationName }),
    strollers: intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have stroller-friendly trails?' }, { locationName }),
    waterfall: intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have waterfall trails?' }, { locationName }),
    wildlife: intl.formatMessage({ defaultMessage: 'What national parks in {locationName} have wildlife trails?' }, { locationName })
  };

  return translations[messageKey];
};

type NationalParksFirstAnswerWithFilterProps = {
  filter?: NationalParksFaqPageFilter;
  locationName: JSX.Element;
  trailCount: string;
  averageRating: string;
  reviewCount: string;
  intl: IntlShape;
};
export const NationalParksFirstAnswerWithFilter = ({
  filter,
  locationName,
  trailCount,
  averageRating,
  reviewCount,
  intl
}: NationalParksFirstAnswerWithFilterProps) => {
  const messageKey = filter || 'default';

  const translations = {
    default: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} hiking trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    backpacking: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} backpacking trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'bike-touring': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} bike touring trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    birding: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} bird watching trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    camping: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} camping trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    fishing: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} fishing trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    hiking: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} hiking trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'horseback-riding': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} horseback riding trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'mountain-biking': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} mountain biking trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'off-road-driving': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} off-road driving trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'road-biking': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} road biking trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'rock-climbing': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} rock climbing trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'scenic-driving': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} scenic driving trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    skiing: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} skiing trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    snowshoeing: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} snowshoeing trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'trail-running': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} running trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'via-ferrata': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} via ferrata trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    walking: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} walking trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'cross-country-skiing': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} cross country skiing trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    ada: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} wheelchair-friendly trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    beach: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} beach trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    cave: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} cave trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    dogs: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} dog-friendly trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    forest: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} forest trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'historic-site': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} historic site trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'hot-springs': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} trails with hot springs with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    kids: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} kid-friendly trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    lake: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} lake trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'partially-paved': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} partially-paved trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    paved: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} paved trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'rails-trails': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} rails trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    river: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} river trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    strollers: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} stroller-friendly trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    waterfall: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} waterfall trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    wildlife: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} is home to {trailCount} wildlife trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    )
  };

  return translations[messageKey];
};

type NationalParksAnswerWithFilterProps = {
  filter?: NationalParksFaqPageFilter;
  locationName: JSX.Element;
  trailCount: string;
  averageRating: string;
  reviewCount: string;
  intl: IntlShape;
};
export const NationalParksAnswerWithFilter = ({
  filter,
  locationName,
  trailCount,
  averageRating,
  reviewCount,
  intl
}: NationalParksAnswerWithFilterProps) => {
  const messageKey = filter || 'default';

  const translations = {
    default: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} hiking trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    backpacking: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} backpacking trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'bike-touring': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} bike touring trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    birding: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} bird watching trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    camping: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} camping trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    fishing: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} fishing trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    hiking: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} hiking trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'horseback-riding': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} horseback riding trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'mountain-biking': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} mountain biking trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'off-road-driving': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} off-road driving trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'road-biking': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} road biking trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'rock-climbing': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} rock climbing trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'scenic-driving': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} scenic driving trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    skiing: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} skiing trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    snowshoeing: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} snowshoeing trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'trail-running': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} running trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'via-ferrata': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} via ferrata trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    walking: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} walking trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'cross-country-skiing': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} cross country skiing trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    ada: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} wheelchair-friendly trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    beach: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} beach trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    cave: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} cave trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    dogs: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} dog-friendly trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    forest: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} forest trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'historic-site': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} historic site trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'hot-springs': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} trails with hot springs with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    kids: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} kid-friendly trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    lake: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} lake trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'partially-paved': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} partially-paved trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    paved: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} paved trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    'rails-trails': intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} rails trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    river: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} river trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    strollers: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} stroller-friendly trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    waterfall: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} waterfall trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    ),
    wildlife: intl.formatMessage(
      {
        defaultMessage:
          '{locationName} has {trailCount} wildlife trails with an average {averageRating} star rating from {reviewCount} community reviews.'
      },
      { locationName, trailCount, averageRating, reviewCount }
    )
  };

  return translations[messageKey];
};
