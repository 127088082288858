import { type IntlShape } from 'react-intl';
import { metersToFormattedUserUnits } from '@alltrails/filters/utils/mapStats';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { FaqFilterType } from '../types';
import { FAQTrail, LocationDetailsResponseType } from '../../../types/locationDetailsPages/shared';
import { TrailFaqLink } from '../FaqLink';
import {
  LongestTrailAnswerWithFilter,
  LongestTrailParkAnswerWithFilter,
  getLongestTrailParkQuestionString,
  getLongestTrailQuestionString
} from './LongestTrailMessages';

export const getLongestTrailQuestion = (intl: IntlShape, locationName: string, locationType: LocationDetailsResponseType, filter?: FaqFilterType) => {
  if (locationType === 'poi') {
    // POI filter pages do not exist, so no variations of this question are necessary
    return intl.formatMessage({ defaultMessage: 'What is the longest trail near {locationName}?' }, { locationName });
  }

  if (locationType === 'park') {
    return getLongestTrailParkQuestionString(intl, locationName, filter);
  }

  return getLongestTrailQuestionString(intl, locationName, filter);
};

type LongestTrailAnswerProps = {
  filter?: FaqFilterType;
  locationName: string;
  longestTrail: FAQTrail;
  locationType: LocationDetailsResponseType;
  languageRegionCode: LanguageRegionCode;
  displayMetric: boolean;
  intl: IntlShape;
};

export const LongestTrailAnswer = ({
  filter,
  locationName,
  longestTrail,
  locationType,
  languageRegionCode,
  displayMetric,
  intl
}: LongestTrailAnswerProps) => {
  const { length } = longestTrail;
  const displayLength = metersToFormattedUserUnits(length, 1, displayMetric, languageRegionCode);

  let sentence1;
  if (locationType === 'poi') {
    sentence1 = intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest trail near {locationName} is {trailName}.' },
      {
        locationName,
        trailName: <TrailFaqLink languageRegionCode={languageRegionCode} trail={longestTrail} />
      }
    );
  } else if (locationType === 'park') {
    sentence1 = (
      <LongestTrailParkAnswerWithFilter
        filter={filter}
        parkName={locationName}
        trailName={<TrailFaqLink languageRegionCode={languageRegionCode} trail={longestTrail} />}
        intl={intl}
      />
    );
  } else {
    sentence1 = (
      <LongestTrailAnswerWithFilter
        filter={filter}
        locationName={locationName}
        trailName={<TrailFaqLink languageRegionCode={languageRegionCode} trail={longestTrail} />}
        intl={intl}
      />
    );
  }

  const sentence2 = intl.formatMessage(
    { defaultMessage: 'This trail is estimated to be {displayLength} long.' },
    {
      displayLength
    }
  );

  return (
    <>
      {sentence1} {sentence2}
    </>
  );
};
