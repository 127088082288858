import { useEffect, useMemo, useRef } from 'react';
import classNames from 'classnames';
import InputField from '../InputField';
import { InputBaseProps, Size } from '../../types';
import getErrorMessage from '../TextInput/getErrorMessage';
import styles from './styles/styles.module.scss';

type TextAreaProps = InputBaseProps & {
  characterLimit?: number;
  className?: string;
  isFocused?: boolean;
  onChange: (value: string) => void;
  onFocus?: () => void;
  placeholder?: string;
  required?: boolean;
  resizable?: boolean;
  rows?: number;
  testId: string;
  value?: string;
  size?: Size<'sm' | 'md'>;
};

const TextArea = ({
  className,
  disabled,
  errorText,
  helperText,
  hideLabel,
  labelText,
  labelTextVariant,
  onChange,
  onFocus,
  placeholder,
  required = false,
  resizable = false,
  rows = 3,
  testId,
  value,
  isFocused,
  characterLimit,
  size = 'md'
}: TextAreaProps): JSX.Element => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (isFocused && textAreaRef.current) {
      textAreaRef.current.focus();
      // setting focus to the end of input
      if (value) {
        textAreaRef.current.setSelectionRange(value.length, value.length);
      }
    }
  }, [isFocused, value]);

  const errorMessage = getErrorMessage(errorText, value, required, characterLimit);

  return (
    <InputField
      className={className}
      disabled={disabled}
      errorText={errorMessage}
      helperText={helperText}
      labelText={hideLabel ? '' : labelText}
      labelTextVariant={labelTextVariant}
      characterLimitText={value && characterLimit ? `${value.length} / ${characterLimit}` : undefined}
      size={size}
    >
      <textarea
        ref={textAreaRef}
        aria-label={hideLabel ? labelText : undefined}
        className={classNames(styles.textArea, { [styles.resizable]: resizable, [styles.hasError]: Boolean(errorMessage) })}
        disabled={disabled}
        onChange={e => {
          onChange(e.target.value);
        }}
        onFocus={() => onFocus?.()}
        placeholder={placeholder}
        required={required}
        rows={Math.max(1, rows)}
        value={value}
        data-testid={testId}
      />
    </InputField>
  );
};

export default TextArea;
