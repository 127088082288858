import { type IntlShape } from 'react-intl';
import { FaqFilterType } from '../types';

export const getBestTrailQuestionString = (intl: IntlShape, parkName: string, filter?: FaqFilterType) => {
  const messageKey = filter || 'default';

  const translations = {
    default: intl.formatMessage({ defaultMessage: 'Where is the best place to hike in {parkName}?' }, { parkName }),
    backpacking: intl.formatMessage({ defaultMessage: 'Where is the best trail to hike for backpacking in {parkName}?' }, { parkName }),
    'bike-touring': intl.formatMessage({ defaultMessage: 'Where is the best trail for bike touring in {parkName}?' }, { parkName }),
    birding: intl.formatMessage({ defaultMessage: 'Where is the best trail to hike for bird watching in {parkName}?' }, { parkName }),
    camping: intl.formatMessage({ defaultMessage: 'Where is the best trail to hike for camping in {parkName}?' }, { parkName }),
    fishing: intl.formatMessage({ defaultMessage: 'Where is the best trail to hike for fishing in {parkName}?' }, { parkName }),
    hiking: intl.formatMessage({ defaultMessage: 'Where is the best trail to hike in {parkName}?' }, { parkName }),
    'horseback-riding': intl.formatMessage({ defaultMessage: 'Where is the best trail for horseback riding in {parkName}?' }, { parkName }),
    'mountain-biking': intl.formatMessage({ defaultMessage: 'Where is the best trail for mountain biking in {parkName}?' }, { parkName }),
    'off-road-driving': intl.formatMessage({ defaultMessage: 'Where is the best trail for off-road driving in {parkName}?' }, { parkName }),
    'road-biking': intl.formatMessage({ defaultMessage: 'Where is the best trail for road biking in {parkName}?' }, { parkName }),
    'rock-climbing': intl.formatMessage({ defaultMessage: 'Where is the best trail to hike for rock climbing in {parkName}?' }, { parkName }),
    'scenic-driving': intl.formatMessage({ defaultMessage: 'Where is the best trail for scenic driving in {parkName}?' }, { parkName }),
    skiing: intl.formatMessage({ defaultMessage: 'Where is the best trail for skiing in {parkName}?' }, { parkName }),
    snowshoeing: intl.formatMessage({ defaultMessage: 'Where is the best hiking trail for snowshoeing in {parkName}?' }, { parkName }),
    'trail-running': intl.formatMessage({ defaultMessage: 'Where is the best hiking trail for running in {parkName}?' }, { parkName }),
    'via-ferrata': intl.formatMessage({ defaultMessage: 'Where is the best trail to hike for via ferrata in {parkName}?' }, { parkName }),
    walking: intl.formatMessage({ defaultMessage: 'Where is the best trail for walking in {parkName}?' }, { parkName }),
    'cross-country-skiing': intl.formatMessage(
      { defaultMessage: 'Where is the best hiking trail for cross country skiing in {parkName}?' },
      { parkName }
    ),
    ada: intl.formatMessage({ defaultMessage: 'Where is the best wheelchair-friendly trail in {parkName}?' }, { parkName }),
    beach: intl.formatMessage({ defaultMessage: 'Where is the best beach trail to hike in {parkName}?' }, { parkName }),
    cave: intl.formatMessage({ defaultMessage: 'Where is the best trail with caves to hike in {parkName}?' }, { parkName }),
    'city-walk': intl.formatMessage({ defaultMessage: 'Where is the best city walk trail to hike in {parkName}?' }, { parkName }),
    dogs: intl.formatMessage({ defaultMessage: 'Where is the best dog-friendly trail to hike in {parkName}?' }, { parkName }),
    forest: intl.formatMessage({ defaultMessage: 'Where is the best forest trail to hike in {parkName}?' }, { parkName }),
    'historic-site': intl.formatMessage({ defaultMessage: 'Where is the best historic site trail to hike in {parkName}?' }, { parkName }),
    'hot-springs': intl.formatMessage({ defaultMessage: 'Where is the best trail with hot springs to hike in {parkName}?' }, { parkName }),
    kids: intl.formatMessage({ defaultMessage: 'Where is the best kid-friendly trail to hike in {parkName}?' }, { parkName }),
    lake: intl.formatMessage({ defaultMessage: 'Where is the best lake trail to hike in {parkName}?' }, { parkName }),
    'partially-paved': intl.formatMessage({ defaultMessage: 'Where is the best partially-paved trail to hike in {parkName}?' }, { parkName }),
    paved: intl.formatMessage({ defaultMessage: 'Where is the best paved trail to hike in {parkName}?' }, { parkName }),
    'pub-crawl': intl.formatMessage({ defaultMessage: 'Where is the best pub walks to hike in {parkName}?' }, { parkName }),
    'rails-trails': intl.formatMessage({ defaultMessage: 'Where is the best rails trail to hike in {parkName}?' }, { parkName }),
    river: intl.formatMessage({ defaultMessage: 'Where is the best river trail to hike in {parkName}?' }, { parkName }),
    strollers: intl.formatMessage({ defaultMessage: 'Where is the best stroller-friendly trail to hike in {parkName}?' }, { parkName }),
    waterfall: intl.formatMessage({ defaultMessage: 'Where is the best waterfall trail to hike in {parkName}?' }, { parkName }),
    wildlife: intl.formatMessage({ defaultMessage: 'Where is the best wildlife trail to hike in {parkName}?' }, { parkName })
  };

  return translations[messageKey];
};

type BestTrailAnswerWithFilterProps = {
  filter?: FaqFilterType;
  parkName: string;
  trailName: JSX.Element;
  starRating: string;
  displayCount: string;
  intl: IntlShape;
};

export const BestTrailAnswerWithFilter = ({ filter, parkName, trailName, starRating, displayCount, intl }: BestTrailAnswerWithFilterProps) => {
  const messageKey = filter || 'default';

  const translations = {
    default: intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best place to hike in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    backpacking: intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best trail to hike for backpacking in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    'bike-touring': intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best trail for bike touring in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    birding: intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best trail to hike for bird watching in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    camping: intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best trail to hike for camping in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    fishing: intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best trail to hike for fishing in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    hiking: intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best trail to hike in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    'horseback-riding': intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best trail for horseback riding in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    'mountain-biking': intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best trail for mountain biking in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    'off-road-driving': intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best trail for off-road driving in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    'road-biking': intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best trail for road biking in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    'rock-climbing': intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best trail to hike for rock climbing in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    'scenic-driving': intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best trail for scenic driving in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    skiing: intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best trail for skiing in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    snowshoeing: intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best hiking trail for snowshoeing in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    'trail-running': intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best hiking trail for running in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    'via-ferrata': intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best trail to hike for via ferrata in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    walking: intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best trail for walking in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    'cross-country-skiing': intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best hiking trail for cross country skiing in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    ada: intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best wheelchair-friendly trail in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    beach: intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best beach trail to hike in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    cave: intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best trail with caves to hike in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    'city-walk': intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best city walk trail to hike in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    dogs: intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best dog-friendly trail to hike in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    forest: intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best forest trail to hike in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    'historic-site': intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best historic site trail to hike in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    'hot-springs': intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best trail with hot springs to hike in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    kids: intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best kid-friendly trail to hike in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    lake: intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best lake trail to hike in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    'partially-paved': intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best partially-paved trail to hike in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    paved: intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best paved trail to hike in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    'pub-crawl': intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best pub walks to hike in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    'rails-trails': intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best rails trail to hike in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    river: intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best river trail to hike in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    strollers: intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best stroller-friendly trail to hike in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    waterfall: intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best waterfall trail to hike in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    ),
    wildlife: intl.formatMessage(
      {
        defaultMessage:
          'According to users from AllTrails.com, the best wildlife trail to hike in {parkName} is {trailName}, which has a {starRating} star rating from {displayCount} reviews.'
      },
      { parkName, trailName, starRating, displayCount }
    )
  };

  return translations[messageKey];
};
