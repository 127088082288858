import classNames from 'classnames';
import { LinkInfo, Size } from '../../types';
import IconRenderer, { IconDefinition } from '../IconRenderer';
import LinkButtonOrDiv from '../LinkButtonOrDiv';
import styles from './styles/styles.module.scss';

type TagProps = {
  className?: string;
  leadingIcon?: IconDefinition<'orientation'>;
  linkInfo?: LinkInfo;
  onClick?: () => void;
  size?: Size<'sm' | 'md'>;
  testId: string;
  text: string;
  trailingIcon?: IconDefinition<'orientation'>;
};

const Tag = ({ className, leadingIcon, linkInfo, onClick, size = 'md', testId, text, trailingIcon }: TagProps): JSX.Element => (
  <LinkButtonOrDiv className={classNames(className, styles.tag, styles[size])} linkInfo={linkInfo} onClick={onClick} testId={testId}>
    {leadingIcon ? <IconRenderer icon={leadingIcon} defaults={{ color: '--color-text-secondary', size: 'sm' }} /> : null}
    <span className={styles.textContent}>{text}</span>
    {trailingIcon ? <IconRenderer icon={trailingIcon} defaults={{ color: '--color-text-secondary', size: 'sm' }} /> : null}
  </LinkButtonOrDiv>
);

export default Tag;
