import classNames from 'classnames';
import { Image, Typography } from '@alltrails/core';
import { LinkTarget, Size } from '@alltrails/denali/types';
import { FormattedMessage } from '@alltrails/shared/react-intl';
import parse, { DOMNode, Element } from 'html-react-parser';
import { ComponentProps } from 'react';
import getPlainTextFromReactNode from '../utils/getPlainTextFromReactNode';
import styles from './styles/styles.module.scss';

const removeEmTagContent = {
  replace: (domNode: DOMNode) => {
    if (domNode instanceof Element && domNode.attribs && domNode.name === 'em') {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      return <></>;
    }

    return undefined;
  }
};

type GuideCardProps = {
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  externalControls?: { guideId: number | null; onFocus: (guideId: number | null) => void };
  photoUrl: ComponentProps<typeof Image>['src'];
  size?: Size<'sm' | 'md'>;
  tabIndex?: number;
  title: string;
  trailCount?: number;
  altText?: string;
  maxWidth?: number;
  href: string;
  target?: LinkTarget;
};

const GuideCard = ({
  className,
  externalControls,
  onClick,
  photoUrl,
  size = 'md',
  tabIndex,
  title,
  trailCount,
  altText,
  maxWidth,
  href,
  target = '_self'
}: GuideCardProps) => {
  const parsedTitle = parse(title);
  const imageAltText = altText || parse(title, removeEmTagContent); // only return the location of the guide
  const handleAddFocus = () => externalControls?.onFocus?.(externalControls.guideId);
  const handleRemoveFocus = () => externalControls?.onFocus?.(null);

  return (
    <a
      href={href}
      onFocus={handleAddFocus}
      onBlur={handleRemoveFocus}
      onMouseEnter={handleAddFocus}
      onMouseLeave={handleRemoveFocus}
      tabIndex={tabIndex}
      className={classNames(styles.guideCardButtonContainer, styles[size], className)}
      onClick={onClick}
      target={target}
    >
      <>
        <div className={styles.guideCardImageContainer}>
          <Image
            className={styles.guideCardImage}
            src={photoUrl}
            alt={getPlainTextFromReactNode(imageAltText)}
            width={maxWidth || 0}
            height={(maxWidth || 0) * (2 / 3)}
            style={{ transition: 'opacity 0.3s ease-in-out, transform 0.3s ease-in-out' }}
            sizes="(max-width: 540px) 100vw, (max-width: 1120px) 50vw, 25vw"
            layout={maxWidth ? 'responsive' : undefined}
          />
        </div>
        <div className={styles.guideCardBody}>
          <div className={styles.guideCardTitle}>{parsedTitle}</div>
          {size !== 'sm' ? (
            <div className={styles.guideCardTrailCount}>
              {trailCount && (
                <Typography variant="text100" color="secondary">
                  <FormattedMessage
                    defaultMessage="{trailCount, plural, one {# trail} other {# trails}}"
                    values={{
                      trailCount
                    }}
                  />
                </Typography>
              )}
            </div>
          ) : null}
        </div>
      </>
    </a>
  );
};

export default GuideCard;
