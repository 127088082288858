import { useCallback, useEffect, useState } from 'react';
import { PhotoSize, CardType } from '@alltrails/shared/types/photos';
import { getCardPhotoUrl } from '@alltrails/shared/utils/requests/photoRequests';

const useCardPhotos = (cardType: CardType, id: number, size: PhotoSize, maxPhotos: number, baseUrl?: string) => {
  const [photos, setPhotos] = useState<string[]>([]);
  const [photosLoaded, setPhotosLoaded] = useState(false);

  useEffect(() => {
    if (maxPhotos < 1) {
      setPhotos([]);
      setPhotosLoaded(true);
    } else {
      const initialPhoto = getCardPhotoUrl(cardType, id, 0, size, baseUrl);
      if (maxPhotos === 1) {
        setPhotos([initialPhoto]);
        setPhotosLoaded(true);
      } else {
        const fillerPhotos = Array(maxPhotos - 1).fill('') as string[];
        setPhotos([initialPhoto, ...fillerPhotos]);
        setPhotosLoaded(false);
      }
    }
  }, [size, maxPhotos]);

  const loadPhotos = useCallback(() => {
    if (!photosLoaded) {
      setPhotosLoaded(true);

      const urls = photos.map((_, index) => {
        const url = getCardPhotoUrl(cardType, id, index, size, baseUrl);
        // pre-load images by browser
        try {
          new Image().src = url;
        } catch (error) {
          // silently catch
        }
        return url;
      });

      setPhotos(urls);
    }
  }, [baseUrl, cardType, id, photos, photosLoaded, size]);

  return { photos, loadPhotos };
};

export default useCardPhotos;
