import { type IntlShape } from 'react-intl';
import { FaqFilterType } from '../types';

export const getLongestTrailQuestionString = (intl: IntlShape, locationName: string, filter?: FaqFilterType) => {
  const messageKey = filter || 'default';

  const translations = {
    default: intl.formatMessage({ defaultMessage: 'What is the longest trail in {locationName}?' }, { locationName }),
    backpacking: intl.formatMessage({ defaultMessage: 'What is the longest backpacking trail in {locationName}?' }, { locationName }),
    'bike-touring': intl.formatMessage({ defaultMessage: 'What is the longest bike touring trail in {locationName}?' }, { locationName }),
    birding: intl.formatMessage({ defaultMessage: 'What is the longest bird watching trail in {locationName}?' }, { locationName }),
    camping: intl.formatMessage({ defaultMessage: 'What is the longest camping trail in {locationName}?' }, { locationName }),
    fishing: intl.formatMessage({ defaultMessage: 'What is the longest fishing trail in {locationName}?' }, { locationName }),
    hiking: intl.formatMessage({ defaultMessage: 'What is the longest hiking trail in {locationName}?' }, { locationName }),
    'horseback-riding': intl.formatMessage({ defaultMessage: 'What is the longest horseback riding trail in {locationName}?' }, { locationName }),
    'mountain-biking': intl.formatMessage({ defaultMessage: 'What is the longest mountain biking trail in {locationName}?' }, { locationName }),
    'off-road-driving': intl.formatMessage({ defaultMessage: 'What is the longest off-road driving trail in {locationName}?' }, { locationName }),
    'road-biking': intl.formatMessage({ defaultMessage: 'What is the longest road biking trail in {locationName}?' }, { locationName }),
    'rock-climbing': intl.formatMessage({ defaultMessage: 'What is the longest rock climbing trail in {locationName}?' }, { locationName }),
    'scenic-driving': intl.formatMessage({ defaultMessage: 'What is the longest scenic driving trail in {locationName}?' }, { locationName }),
    skiing: intl.formatMessage({ defaultMessage: 'What is the longest skiing trail in {locationName}?' }, { locationName }),
    snowshoeing: intl.formatMessage({ defaultMessage: 'What is the longest snowshoeing trail in {locationName}?' }, { locationName }),
    'trail-running': intl.formatMessage({ defaultMessage: 'What is the longest running trail in {locationName}?' }, { locationName }),
    'via-ferrata': intl.formatMessage({ defaultMessage: 'What is the longest via ferrata trail in {locationName}?' }, { locationName }),
    walking: intl.formatMessage({ defaultMessage: 'What is the longest walking trail in {locationName}?' }, { locationName }),
    'cross-country-skiing': intl.formatMessage(
      { defaultMessage: 'What is the longest cross country skiing trail in {locationName}?' },
      { locationName }
    ),
    ada: intl.formatMessage({ defaultMessage: 'What is the longest wheelchair-friendly trail in {locationName}?' }, { locationName }),
    beach: intl.formatMessage({ defaultMessage: 'What is the longest beach trail in {locationName}?' }, { locationName }),
    cave: intl.formatMessage({ defaultMessage: 'What is the longest trail with caves in {locationName}?' }, { locationName }),
    'city-walk': intl.formatMessage({ defaultMessage: 'What is the longest city walk trail in {locationName}?' }, { locationName }),
    dogs: intl.formatMessage({ defaultMessage: 'What is the longest dog-friendly trail in {locationName}?' }, { locationName }),
    forest: intl.formatMessage({ defaultMessage: 'What is the longest forest trail in {locationName}?' }, { locationName }),
    'historic-site': intl.formatMessage({ defaultMessage: 'What is the longest historic site trail in {locationName}?' }, { locationName }),
    'hot-springs': intl.formatMessage({ defaultMessage: 'What is the longest trail with hot springs in {locationName}?' }, { locationName }),
    kids: intl.formatMessage({ defaultMessage: 'What is the longest kid-friendly trail in {locationName}?' }, { locationName }),
    lake: intl.formatMessage({ defaultMessage: 'What is the longest lake trail in {locationName}?' }, { locationName }),
    'partially-paved': intl.formatMessage({ defaultMessage: 'What is the longest partially-paved trail in {locationName}?' }, { locationName }),
    paved: intl.formatMessage({ defaultMessage: 'What is the longest paved trail in {locationName}?' }, { locationName }),
    'pub-crawl': intl.formatMessage({ defaultMessage: 'What is the longest pub walk in {locationName}?' }, { locationName }),
    'rails-trails': intl.formatMessage({ defaultMessage: 'What is the longest rails trail in {locationName}?' }, { locationName }),
    river: intl.formatMessage({ defaultMessage: 'What is the longest river trail in {locationName}?' }, { locationName }),
    strollers: intl.formatMessage({ defaultMessage: 'What is the longest stroller-friendly trail in {locationName}?' }, { locationName }),
    waterfall: intl.formatMessage({ defaultMessage: 'What is the longest waterfall trail in {locationName}?' }, { locationName }),
    wildlife: intl.formatMessage({ defaultMessage: 'What is the longest wildlife trail in {locationName}?' }, { locationName })
  };

  return translations[messageKey];
};

// For translation purposes, whether the location is a city/state/region or a park matters
export const getLongestTrailParkQuestionString = (intl: IntlShape, parkName: string, filter?: FaqFilterType) => {
  const messageKey = filter || 'default';

  const translations = {
    default: intl.formatMessage({ defaultMessage: 'What is the longest trail in {parkName}?' }, { parkName }),
    backpacking: intl.formatMessage({ defaultMessage: 'What is the longest backpacking trail in {parkName}?' }, { parkName }),
    'bike-touring': intl.formatMessage({ defaultMessage: 'What is the longest bike touring trail in {parkName}?' }, { parkName }),
    birding: intl.formatMessage({ defaultMessage: 'What is the longest bird watching trail in {parkName}?' }, { parkName }),
    camping: intl.formatMessage({ defaultMessage: 'What is the longest camping trail in {parkName}?' }, { parkName }),
    fishing: intl.formatMessage({ defaultMessage: 'What is the longest fishing trail in {parkName}?' }, { parkName }),
    hiking: intl.formatMessage({ defaultMessage: 'What is the longest hiking trail in {parkName}?' }, { parkName }),
    'horseback-riding': intl.formatMessage({ defaultMessage: 'What is the longest horseback riding trail in {parkName}?' }, { parkName }),
    'mountain-biking': intl.formatMessage({ defaultMessage: 'What is the longest mountain biking trail in {parkName}?' }, { parkName }),
    'off-road-driving': intl.formatMessage({ defaultMessage: 'What is the longest off-road driving trail in {parkName}?' }, { parkName }),
    'road-biking': intl.formatMessage({ defaultMessage: 'What is the longest road biking trail in {parkName}?' }, { parkName }),
    'rock-climbing': intl.formatMessage({ defaultMessage: 'What is the longest rock climbing trail in {parkName}?' }, { parkName }),
    'scenic-driving': intl.formatMessage({ defaultMessage: 'What is the longest scenic driving trail in {parkName}?' }, { parkName }),
    skiing: intl.formatMessage({ defaultMessage: 'What is the longest skiing trail in {parkName}?' }, { parkName }),
    snowshoeing: intl.formatMessage({ defaultMessage: 'What is the longest snowshoeing trail in {parkName}?' }, { parkName }),
    'trail-running': intl.formatMessage({ defaultMessage: 'What is the longest running trail in {parkName}?' }, { parkName }),
    'via-ferrata': intl.formatMessage({ defaultMessage: 'What is the longest via ferrata trail in {parkName}?' }, { parkName }),
    walking: intl.formatMessage({ defaultMessage: 'What is the longest walking trail in {parkName}?' }, { parkName }),
    'cross-country-skiing': intl.formatMessage({ defaultMessage: 'What is the longest cross country skiing trail in {parkName}?' }, { parkName }),
    ada: intl.formatMessage({ defaultMessage: 'What is the longest wheelchair-friendly trail in {parkName}?' }, { parkName }),
    beach: intl.formatMessage({ defaultMessage: 'What is the longest beach trail in {parkName}?' }, { parkName }),
    cave: intl.formatMessage({ defaultMessage: 'What is the longest trail with caves in {parkName}?' }, { parkName }),
    'city-walk': intl.formatMessage({ defaultMessage: 'What is the longest city walk trail in {parkName}?' }, { parkName }),
    dogs: intl.formatMessage({ defaultMessage: 'What is the longest dog-friendly trail in {parkName}?' }, { parkName }),
    forest: intl.formatMessage({ defaultMessage: 'What is the longest forest trail in {parkName}?' }, { parkName }),
    'historic-site': intl.formatMessage({ defaultMessage: 'What is the longest historic site trail in {parkName}?' }, { parkName }),
    'hot-springs': intl.formatMessage({ defaultMessage: 'What is the longest trail with hot springs in {parkName}?' }, { parkName }),
    kids: intl.formatMessage({ defaultMessage: 'What is the longest kid-friendly trail in {parkName}?' }, { parkName }),
    lake: intl.formatMessage({ defaultMessage: 'What is the longest lake trail in {parkName}?' }, { parkName }),
    'partially-paved': intl.formatMessage({ defaultMessage: 'What is the longest partially-paved trail in {parkName}?' }, { parkName }),
    paved: intl.formatMessage({ defaultMessage: 'What is the longest paved trail in {parkName}?' }, { parkName }),
    'pub-crawl': intl.formatMessage({ defaultMessage: 'What is the longest pub walk in {parkName}?' }, { parkName }),
    'rails-trails': intl.formatMessage({ defaultMessage: 'What is the longest rails trail in {parkName}?' }, { parkName }),
    river: intl.formatMessage({ defaultMessage: 'What is the longest river trail in {parkName}?' }, { parkName }),
    strollers: intl.formatMessage({ defaultMessage: 'What is the longest stroller-friendly trail in {parkName}?' }, { parkName }),
    waterfall: intl.formatMessage({ defaultMessage: 'What is the longest waterfall trail in {parkName}?' }, { parkName }),
    wildlife: intl.formatMessage({ defaultMessage: 'What is the longest wildlife trail in {parkName}?' }, { parkName })
  };

  return translations[messageKey];
};

type LongestTrailAnswerWithFilterProps = {
  filter?: FaqFilterType;
  locationName: string;
  trailName: JSX.Element;
  intl: IntlShape;
};
export const LongestTrailAnswerWithFilter = ({ filter, locationName, trailName, intl }: LongestTrailAnswerWithFilterProps) => {
  const messageKey = filter || 'default';

  const translations = {
    default: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest trail in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    backpacking: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest backpacking trail in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    'bike-touring': intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest bike touring trail in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    birding: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest bird watching trail in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    camping: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest camping trail in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    fishing: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest fishing trail in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    hiking: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest hiking trail in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    'horseback-riding': intl.formatMessage(
      {
        defaultMessage: 'According to AllTrails.com, the longest horseback riding trail in {locationName} is {trailName}.'
      },
      { locationName, trailName }
    ),
    'mountain-biking': intl.formatMessage(
      {
        defaultMessage: 'According to AllTrails.com, the longest mountain biking trail in {locationName} is {trailName}.'
      },
      { locationName, trailName }
    ),
    'off-road-driving': intl.formatMessage(
      {
        defaultMessage: 'According to AllTrails.com, the longest off-road driving trail in {locationName} is {trailName}.'
      },
      { locationName, trailName }
    ),
    'road-biking': intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest road biking trail in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    'rock-climbing': intl.formatMessage(
      {
        defaultMessage: 'According to AllTrails.com, the longest rock climbing trail in {locationName} is {trailName}.'
      },
      { locationName, trailName }
    ),
    'scenic-driving': intl.formatMessage(
      {
        defaultMessage: 'According to AllTrails.com, the longest scenic driving trail in {locationName} is {trailName}.'
      },
      { locationName, trailName }
    ),
    skiing: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest skiing trail in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    snowshoeing: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest snowshoeing trail in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    'trail-running': intl.formatMessage(
      {
        defaultMessage: 'According to AllTrails.com, the longest running trail in {locationName} is {trailName}.'
      },
      { locationName, trailName }
    ),
    'via-ferrata': intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest via ferrata trail in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    walking: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest walking trail in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    'cross-country-skiing': intl.formatMessage(
      {
        defaultMessage: 'According to AllTrails.com, the longest cross country skiing trail in {locationName} is {trailName}.'
      },
      { locationName, trailName }
    ),
    ada: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest wheelchair-friendly trail in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    beach: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest beach trail in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    cave: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest trail with caves in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    'city-walk': intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest city walk trail in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    dogs: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest dog-friendly trail in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    forest: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest forest trail in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    'historic-site': intl.formatMessage(
      {
        defaultMessage: 'According to AllTrails.com, the longest historic site trail in {locationName} is {trailName}.'
      },
      { locationName, trailName }
    ),
    'hot-springs': intl.formatMessage(
      {
        defaultMessage: 'According to AllTrails.com, the longest trail with hot springs in {locationName} is {trailName}.'
      },
      { locationName, trailName }
    ),
    kids: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest kid-friendly trail in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    lake: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest lake trail in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    'partially-paved': intl.formatMessage(
      {
        defaultMessage: 'According to AllTrails.com, the longest partially-paved trail in {locationName} is {trailName}.'
      },
      { locationName, trailName }
    ),
    paved: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest paved trail in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    'pub-crawl': intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest pub walk in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    'rails-trails': intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest rails trail in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    river: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest river trail in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    strollers: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest stroller-friendly trail in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    waterfall: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest waterfall trail in {locationName} is {trailName}.' },
      { locationName, trailName }
    ),
    wildlife: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest wildlife trail in {locationName} is {trailName}.' },
      { locationName, trailName }
    )
  };

  return translations[messageKey];
};

type LongestTrailParkAnswerWithFilterProps = {
  filter?: FaqFilterType;
  parkName: string;
  trailName: JSX.Element;
  intl: IntlShape;
};
export const LongestTrailParkAnswerWithFilter = ({ filter, parkName, trailName, intl }: LongestTrailParkAnswerWithFilterProps) => {
  const messageKey = filter || 'default';

  const translations = {
    default: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest trail in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    backpacking: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest backpacking trail in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    'bike-touring': intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest bike touring trail in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    birding: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest bird watching trail in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    camping: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest camping trail in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    fishing: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest fishing trail in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    hiking: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest hiking trail in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    'horseback-riding': intl.formatMessage(
      {
        defaultMessage: 'According to AllTrails.com, the longest horseback riding trail in {parkName} is {trailName}.'
      },
      { parkName, trailName }
    ),
    'mountain-biking': intl.formatMessage(
      {
        defaultMessage: 'According to AllTrails.com, the longest mountain biking trail in {parkName} is {trailName}.'
      },
      { parkName, trailName }
    ),
    'off-road-driving': intl.formatMessage(
      {
        defaultMessage: 'According to AllTrails.com, the longest off-road driving trail in {parkName} is {trailName}.'
      },
      { parkName, trailName }
    ),
    'road-biking': intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest road biking trail in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    'rock-climbing': intl.formatMessage(
      {
        defaultMessage: 'According to AllTrails.com, the longest rock climbing trail in {parkName} is {trailName}.'
      },
      { parkName, trailName }
    ),
    'scenic-driving': intl.formatMessage(
      {
        defaultMessage: 'According to AllTrails.com, the longest scenic driving trail in {parkName} is {trailName}.'
      },
      { parkName, trailName }
    ),
    skiing: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest skiing trail in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    snowshoeing: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest snowshoeing trail in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    'trail-running': intl.formatMessage(
      {
        defaultMessage: 'According to AllTrails.com, the longest running trail in {parkName} is {trailName}.'
      },
      { parkName, trailName }
    ),
    'via-ferrata': intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest via ferrata trail in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    walking: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest walking trail in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    'cross-country-skiing': intl.formatMessage(
      {
        defaultMessage: 'According to AllTrails.com, the longest cross country skiing trail in {parkName} is {trailName}.'
      },
      { parkName, trailName }
    ),
    ada: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest wheelchair-friendly trail in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    beach: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest beach trail in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    cave: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest trail with caves in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    'city-walk': intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest city walk trail in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    dogs: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest dog-friendly trail in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    forest: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest forest trail in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    'historic-site': intl.formatMessage(
      {
        defaultMessage: 'According to AllTrails.com, the longest historic site trail in {parkName} is {trailName}.'
      },
      { parkName, trailName }
    ),
    'hot-springs': intl.formatMessage(
      {
        defaultMessage: 'According to AllTrails.com, the longest trail with hot springs in {parkName} is {trailName}.'
      },
      { parkName, trailName }
    ),
    kids: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest kid-friendly trail in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    lake: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest lake trail in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    'partially-paved': intl.formatMessage(
      {
        defaultMessage: 'According to AllTrails.com, the longest partially-paved trail in {parkName} is {trailName}.'
      },
      { parkName, trailName }
    ),
    paved: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest paved trail in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    'pub-crawl': intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest pub walk in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    'rails-trails': intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest rails trail in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    river: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest river trail in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    strollers: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest stroller-friendly trail in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    waterfall: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest waterfall trail in {parkName} is {trailName}.' },
      { parkName, trailName }
    ),
    wildlife: intl.formatMessage(
      { defaultMessage: 'According to AllTrails.com, the longest wildlife trail in {parkName} is {trailName}.' },
      { parkName, trailName }
    )
  };

  return translations[messageKey];
};
