import type { FAQPage } from 'schema-dts';
import { JsonLdScript } from '@alltrails/core';

type FaqItem = {
  question: string;

  // This needs to be a string but can include some tags: https://developers.google.com/search/docs/appearance/structured-data/faqpage#answer
  // Use renderToStaticMarkup to render server components to strings
  answer: string;
};

type FaqPageStructuredDataProps = {
  faqItems: FaqItem[];
};

const FaqPageStructuredData = ({ faqItems }: FaqPageStructuredDataProps) => {
  return (
    <JsonLdScript<FAQPage>
      data={{
        '@type': 'FAQPage',
        mainEntity: faqItems.map(({ question, answer }) => ({
          '@type': 'Question',
          name: question,
          acceptedAnswer: {
            '@type': 'Answer',
            text: answer
          }
        }))
      }}
    />
  );
};

export default FaqPageStructuredData;
