import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

const getErrorMessage = (errorText?: ReactNode, value?: string, required?: boolean, characterLimit?: number) => {
  if (required && value?.length === 0) {
    return <FormattedMessage defaultMessage="Required field" />;
  }
  if (characterLimit && value && value.length > characterLimit) {
    return <FormattedMessage defaultMessage="Character limit exceeded" />;
  }
  return errorText;
};

export default getErrorMessage;
