import { toATLocalString } from '@alltrails/language';
import { metersToFeet, metersToMiles } from '@alltrails/data-formatting';

export const formatData = (data, precision, measurementUnit, languageRegionCode = 'en-US') => {
  if (data || data === 0) {
    let formatted = `${toATLocalString(data, languageRegionCode, precision)}`;

    if (measurementUnit) {
      formatted = `${formatted} ${measurementUnit}`;
    }

    return formatted;
  }

  if (measurementUnit) {
    return `0 ${measurementUnit}`;
  }

  return '';
};

export const elevationFormatted = (value, metric, languageRegionCode) => {
  if (!value && value !== 0) {
    return '--';
  }

  const [elevation, measurementType] = metric ? [value, 'm'] : [metersToFeet(value), 'ft'];
  return formatData(elevation, 0, measurementType, languageRegionCode);
};

export const metersToFormattedUserUnits = (length, precision = 2, metric, languageRegionCode) => {
  // returns a formatted string of meters to either km or mi
  const [distance, measurementType] = metric ? [length / 1000.0, 'km'] : [metersToMiles(length), 'mi'];
  return formatData(distance, precision, measurementType, languageRegionCode);
};
