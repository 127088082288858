import GuideSourceType from '@alltrails/analytics/enums/GuideSourceType';
import { LocationDetailsResponseType } from '@/types/locationDetailsPages/shared';

export const getGuideSourceType = (type?: LocationDetailsResponseType): GuideSourceType => {
  switch (type) {
    case 'park':
      return GuideSourceType.ParkPage;
    default:
      return GuideSourceType.LocationPage;
  }
};
