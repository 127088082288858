import { ReactNode } from 'react';
import { type IntlShape } from 'react-intl';
import { toATLocalString } from '@alltrails/language';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { FaqLink } from '../FaqLink';
import { FAQNationalParkType } from '../../../types/locationDetailsPages/shared';
import { FaqFilterType } from '../types';
import { getNationalParksQuestionString, NationalParksFirstAnswerWithFilter, NationalParksAnswerWithFilter } from './NationalParksMessages';

export type NationalParksFaqPageFilter = Exclude<FaqFilterType, 'pub-crawl' | 'city-walk'>;

type FormattedFAQNationalParkType = {
  name: string;
  path: string;
  trailCount: string;
  reviewCount: string;
  averageRating: string;
};

export const getNationalParkQuestion = (intl: IntlShape, locationName: string, filter?: NationalParksFaqPageFilter) => {
  return getNationalParksQuestionString(intl, locationName, filter);
};

const formatParkValues = (park: FAQNationalParkType, languageRegionCode: LanguageRegionCode): FormattedFAQNationalParkType => ({
  name: park.name,
  path: `/parks/${park.slug}`,
  trailCount: toATLocalString(park.trailCount, languageRegionCode),
  averageRating: toATLocalString(park.averageRating, languageRegionCode, 1),
  reviewCount: toATLocalString(park.reviewCount, languageRegionCode)
});

type NationalParkAnswerProps = {
  filter?: NationalParksFaqPageFilter;
  locationName: string;
  faqNationalParks: FAQNationalParkType[];
  languageRegionCode: LanguageRegionCode;
  intl: IntlShape;
};

export const NationalParkAnswer = ({ filter, locationName, faqNationalParks, intl, languageRegionCode }: NationalParkAnswerProps) => {
  const park1 = formatParkValues(faqNationalParks[0], languageRegionCode);
  const sentences = [
    intl.formatMessage({ defaultMessage: 'Hikers on AllTrails.com recommend several popular national parks in {locationName}.' }, { locationName }),
    <NationalParksFirstAnswerWithFilter
      filter={filter}
      locationName={<FaqLink href={park1.path} title={park1.name} languageRegionCode={languageRegionCode} />}
      key="national-park-answer-2"
      trailCount={park1.trailCount}
      averageRating={park1.averageRating}
      reviewCount={park1.reviewCount}
      intl={intl}
    />
  ] as Array<ReactNode>;

  if (faqNationalParks.length > 1) {
    const park2 = formatParkValues(faqNationalParks[1], languageRegionCode);
    sentences.push(
      <NationalParksAnswerWithFilter
        key="national-park-answer-3"
        filter={filter}
        locationName={<FaqLink href={park2.path} title={park2.name} languageRegionCode={languageRegionCode} />}
        trailCount={park2.trailCount}
        averageRating={park2.averageRating}
        reviewCount={park2.reviewCount}
        intl={intl}
      />
    );
  }

  if (faqNationalParks.length > 2) {
    const park3 = formatParkValues(faqNationalParks[2], languageRegionCode);
    sentences.push(
      <NationalParksAnswerWithFilter
        key="national-park-answer-4"
        filter={filter}
        locationName={<FaqLink href={park3.path} title={park3.name} languageRegionCode={languageRegionCode} />}
        trailCount={park3.trailCount}
        averageRating={park3.averageRating}
        reviewCount={park3.reviewCount}
        intl={intl}
      />
    );
  }

  return sentences.reduce((prev, curr) => [prev, ' ', curr], '');
};
