import { type IntlShape } from 'react-intl';
import { FaqFilterType } from '../types';

export const getPopularDifficultTrailQuestionString = (intl: IntlShape, locationName: string, filter?: FaqFilterType) => {
  const messageKey = filter || 'default';

  const translations = {
    default: intl.formatMessage({ defaultMessage: 'What is the most popular and difficult trail in {locationName}?' }, { locationName }),
    backpacking: intl.formatMessage(
      { defaultMessage: 'What is the most popular and difficult backpacking trail in {locationName}?' },
      { locationName }
    ),
    'bike-touring': intl.formatMessage(
      { defaultMessage: 'What is the most popular and difficult bike touring trail in {locationName}?' },
      { locationName }
    ),
    birding: intl.formatMessage(
      { defaultMessage: 'What is the most popular and difficult bird watching trail in {locationName}?' },
      { locationName }
    ),
    camping: intl.formatMessage({ defaultMessage: 'What is the most popular and difficult camping trail in {locationName}?' }, { locationName }),
    fishing: intl.formatMessage({ defaultMessage: 'What is the most popular and difficult fishing trail in {locationName}?' }, { locationName }),
    hiking: intl.formatMessage({ defaultMessage: 'What is the most popular and difficult hiking trail in {locationName}?' }, { locationName }),
    'horseback-riding': intl.formatMessage(
      { defaultMessage: 'What is the most popular and difficult horseback riding trail in {locationName}?' },
      { locationName }
    ),
    'mountain-biking': intl.formatMessage(
      { defaultMessage: 'What is the most popular and difficult mountain biking trail in {locationName}?' },
      { locationName }
    ),
    'off-road-driving': intl.formatMessage(
      { defaultMessage: 'What is the most popular and difficult off-road driving trail in {locationName}?' },
      { locationName }
    ),
    'road-biking': intl.formatMessage(
      { defaultMessage: 'What is the most popular and difficult road biking trail in {locationName}?' },
      { locationName }
    ),
    'rock-climbing': intl.formatMessage(
      { defaultMessage: 'What is the most popular and difficult rock climbing trail in {locationName}?' },
      { locationName }
    ),
    'scenic-driving': intl.formatMessage(
      { defaultMessage: 'What is the most popular and difficult scenic driving trail in {locationName}?' },
      { locationName }
    ),
    skiing: intl.formatMessage({ defaultMessage: 'What is the most popular and difficult skiing trail in {locationName}?' }, { locationName }),
    snowshoeing: intl.formatMessage(
      { defaultMessage: 'What is the most popular and difficult snowshoeing trail in {locationName}?' },
      { locationName }
    ),
    'trail-running': intl.formatMessage(
      { defaultMessage: 'What is the most popular and difficult running trail in {locationName}?' },
      { locationName }
    ),
    'via-ferrata': intl.formatMessage(
      { defaultMessage: 'What is the most popular and difficult via ferrata trail in {locationName}?' },
      { locationName }
    ),
    walking: intl.formatMessage({ defaultMessage: 'What is the most popular and difficult walking trail in {locationName}?' }, { locationName }),
    'cross-country-skiing': intl.formatMessage(
      { defaultMessage: 'What is the most popular and difficult cross country skiing trail in {locationName}?' },
      { locationName }
    ),
    ada: intl.formatMessage(
      { defaultMessage: 'What is the most popular and difficult wheelchair-friendly trail in {locationName}?' },
      { locationName }
    ),
    beach: intl.formatMessage({ defaultMessage: 'What is the most popular and difficult beach trail in {locationName}?' }, { locationName }),
    cave: intl.formatMessage({ defaultMessage: 'What is the most popular and difficult trail with caves in {locationName}?' }, { locationName }),
    'city-walk': intl.formatMessage(
      { defaultMessage: 'What is the most popular and difficult city walk trail in {locationName}?' },
      { locationName }
    ),
    dogs: intl.formatMessage({ defaultMessage: 'What is the most popular and difficult dog-friendly trail in {locationName}?' }, { locationName }),
    forest: intl.formatMessage({ defaultMessage: 'What is the most popular and difficult forest trail in {locationName}?' }, { locationName }),
    'historic-site': intl.formatMessage(
      { defaultMessage: 'What is the most popular and difficult historic site trail in {locationName}?' },
      { locationName }
    ),
    'hot-springs': intl.formatMessage(
      { defaultMessage: 'What is the most popular and difficult trail with hot springs in {locationName}?' },
      { locationName }
    ),
    kids: intl.formatMessage({ defaultMessage: 'What is the most popular and difficult kid-friendly trail in {locationName}?' }, { locationName }),
    lake: intl.formatMessage({ defaultMessage: 'What is the most popular and difficult lake trail in {locationName}?' }, { locationName }),
    'partially-paved': intl.formatMessage(
      { defaultMessage: 'What is the most popular and difficult partially-paved trail in {locationName}?' },
      { locationName }
    ),
    paved: intl.formatMessage({ defaultMessage: 'What is the most popular and difficult paved trail in {locationName}?' }, { locationName }),
    'pub-crawl': intl.formatMessage({ defaultMessage: 'What is the most popular and difficult pub walk in {locationName}?' }, { locationName }),
    'rails-trails': intl.formatMessage({ defaultMessage: 'What is the most popular and difficult rails trail in {locationName}?' }, { locationName }),
    river: intl.formatMessage({ defaultMessage: 'What is the most popular and difficult river trail in {locationName}?' }, { locationName }),
    strollers: intl.formatMessage(
      { defaultMessage: 'What is the most popular and difficult stroller-friendly trail in {locationName}?' },
      { locationName }
    ),
    waterfall: intl.formatMessage({ defaultMessage: 'What is the most popular and difficult waterfall trail in {locationName}?' }, { locationName }),
    wildlife: intl.formatMessage({ defaultMessage: 'What is the most popular and difficult wildlife trail in {locationName}?' }, { locationName })
  };

  return translations[messageKey];
};

type PopularDifficultTrailAnswerWithFilterProps = {
  filter?: FaqFilterType;
  locationName: string;
  trail: JSX.Element;
  rating: string;
  reviewCount: string;
  intl: IntlShape;
};
export const PopularDifficultTrailAnswerWithFilter = ({
  filter,
  locationName,
  trail,
  rating,
  reviewCount,
  intl
}: PopularDifficultTrailAnswerWithFilterProps) => {
  const messageKey = filter || 'default';

  const translations = {
    default: intl.formatMessage(
      {
        defaultMessage: 'The most popular and difficult trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    backpacking: intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult backpacking trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    'bike-touring': intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult bike touring trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    birding: intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult bird watching trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    camping: intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult camping trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    fishing: intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult fishing trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    hiking: intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult hiking trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    'horseback-riding': intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult horseback riding trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    'mountain-biking': intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult mountain biking trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    'off-road-driving': intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult off-road driving trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    'road-biking': intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult road biking trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    'rock-climbing': intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult rock climbing trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    'scenic-driving': intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult scenic driving trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    skiing: intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult skiing trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    snowshoeing: intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult snowshoeing trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    'trail-running': intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult running trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    'via-ferrata': intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult via ferrata trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    walking: intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult walking trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    'cross-country-skiing': intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult cross country skiing trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    ada: intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult wheelchair-friendly trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    beach: intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult beach trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    cave: intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult trail with caves in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    'city-walk': intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult city walk trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    dogs: intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult dog-friendly trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    forest: intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult forest trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    'historic-site': intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult historic site trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    'hot-springs': intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult trail with hot springs in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    kids: intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult kid-friendly trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    lake: intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult lake trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    'partially-paved': intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult partially-paved trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    paved: intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult paved trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    'pub-crawl': intl.formatMessage(
      {
        defaultMessage: 'The most popular and difficult pub walk in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    'rails-trails': intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult rails trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    river: intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult river trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    strollers: intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult stroller-friendly trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    waterfall: intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult waterfall trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    ),
    wildlife: intl.formatMessage(
      {
        defaultMessage:
          'The most popular and difficult wildlife trail in {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      { locationName, trail, rating, reviewCount }
    )
  };

  return translations[messageKey];
};
