import Link from '@alltrails/denali/components/Link';
import { LinkInfo } from '@alltrails/denali/types';
import Location from '@alltrails/denali/icons/Location';
import styles from './ActivityAvatar.module.scss';

export type Props = {
  baseUrl?: string;
  linkInfo: LinkInfo;
};

const ActivityAvatar = ({ linkInfo }: Props): JSX.Element => (
  <Link {...linkInfo}>
    <div className={styles.avatar}>
      <Location size="md" />
    </div>
  </Link>
);

export default ActivityAvatar;
