import { getActivityUrl } from '@alltrails/shared/utils/cardLinks';
import type { Activity } from '@alltrails/shared/types/activity';
import { LinkInfo } from '@alltrails/denali/types';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import EntityLabel from '../EntityLabel';
import ActivityAvatar from '../ActivityAvatar';

export type Props = {
  baseUrl?: string;
  className?: string;
  languageRegionCode: LanguageRegionCode;
  onClick?: () => void;
  secondaryText?: string;
  tertiaryText?: string | { linkInfo: LinkInfo; onClick?: () => void; text: string };
  testIdPrefix?: string;
  activity:
    | Activity
    | {
        name: string;
        slug: string;
      };
  ratingValue?: number;
};

const ActivityLabel = ({
  activity,
  baseUrl,
  className,
  languageRegionCode,
  onClick,
  secondaryText,
  tertiaryText,
  testIdPrefix,
  ratingValue
}: Props): JSX.Element => {
  const { name, slug } = activity;

  const href = getActivityUrl(slug, baseUrl, languageRegionCode);
  const testIdPrefixWithName = testIdPrefix ? `${testIdPrefix}-${name}` : name;

  return (
    <EntityLabel
      avatar={<ActivityAvatar baseUrl={baseUrl} linkInfo={{ href, target: '_blank' }} />}
      className={className}
      link={{ href }}
      onClick={onClick}
      secondaryText={secondaryText}
      tertiaryText={tertiaryText}
      testIdPrefix={testIdPrefixWithName}
      title={name}
      ratingValue={ratingValue}
    />
  );
};

export default ActivityLabel;
