import { ReactNode } from 'react';
import { FormattedMessage, type IntlShape } from 'react-intl';
import classNames from 'classnames';
import { Typography } from '@alltrails/core';
import TrailFeature from '@alltrails/shared/types/trailFeature';
import Access from '@alltrails/filters/constants/accessEnum';
import TrailDifficulty from '@alltrails/shared/types/trailDifficulty';
import { SectionType } from './types';
import labels from './labels';
import styles from './Tags.module.scss';

type TagSectionProps = {
  heading: ReactNode;
  children: ReactNode;
  shouldHideTitle?: boolean;
};

const TagsSection = ({ heading, children, shouldHideTitle }: TagSectionProps) => (
  <div className={styles.column}>
    <Typography className={classNames(styles.title, shouldHideTitle && styles.hiddenTitle)} component="h3" variant="heading200">
      {heading}
    </Typography>
    {children}
  </div>
);

function getFeatureOrAccessLabel(intl: IntlShape, tag: TrailFeature | Access | TrailDifficulty) {
  const message = labels[tag];
  if (!message) {
    throw Error(`missing message for tag ${tag}`);
  }
  return intl.formatMessage(message);
}

const typeToLabel: Record<SectionType, ReactNode> = {
  activity: <FormattedMessage defaultMessage="Activities" />,
  attraction: <FormattedMessage defaultMessage="Attractions" />,
  suitability: <FormattedMessage defaultMessage="Suitability" />,
  poi: <FormattedMessage defaultMessage="Points of interest" />
};

export { TagsSection, getFeatureOrAccessLabel, typeToLabel };
