import { getTrailUrl } from '@alltrails/shared/utils/cardLinks';
import { Trail } from '@alltrails/shared/types/trail';
import type { TrailDetails } from '@alltrails/shared/types/trail';
import type TrailId from '@alltrails/shared/types/trailId';
import { LinkInfo } from '@alltrails/denali/types';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import TrailAvatar from '../TrailAvatar';
import EntityLabel from '../EntityLabel';

export type Props = {
  baseUrl?: string;
  className?: string;
  languageRegionCode: LanguageRegionCode;
  onClick?: () => void;
  secondaryText?: string;
  tertiaryText?: string | { linkInfo: LinkInfo; onClick?: () => void; text: string };
  testIdPrefix?: string;
  ratingValue?: number;
  trail:
    | Trail
    | TrailDetails
    | {
        id: TrailId;
        name: string;
        slug: string;
      };
};

const TrailLabel = ({
  baseUrl,
  className,
  languageRegionCode,
  onClick,
  secondaryText,
  tertiaryText,
  testIdPrefix,
  trail,
  ratingValue
}: Props): JSX.Element => {
  const { id, name, slug } = trail;

  const href = getTrailUrl(slug, baseUrl, languageRegionCode);
  const testIdPrefixWithName = testIdPrefix ? `${testIdPrefix}-${name}` : name;

  return (
    <EntityLabel
      avatar={<TrailAvatar baseUrl={baseUrl} linkInfo={{ href, target: '_blank' }} trailId={id} trailName={name} />}
      className={className}
      link={{ href }}
      onClick={onClick}
      secondaryText={secondaryText}
      tertiaryText={tertiaryText}
      testIdPrefix={testIdPrefixWithName}
      title={name}
      ratingValue={ratingValue}
    />
  );
};

export default TrailLabel;
