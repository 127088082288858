import { FormattedMessage } from 'react-intl';
import Button from '@alltrails/denali/components/Button';
import styles from './ShowMore.module.scss';

type ShowMoreProps = {
  isLoading: boolean;
  onLoadMore: () => void;
};

const ShowMore = ({ isLoading, onLoadMore }: ShowMoreProps) => {
  return (
    <Button
      className={styles.showMore}
      loading={isLoading}
      onClick={onLoadMore}
      testId="trail-reviews-show-more"
      text={<FormattedMessage defaultMessage="Show more" />}
      variant="default"
      width="fullOnMobile"
    />
  );
};

export default ShowMore;
