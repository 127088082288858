import { type IntlShape } from 'react-intl';
import { formatData } from '@alltrails/filters/utils/mapStats';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { ActivityFaq } from '../../../types/locationDetailsPages/shared';
import { TrailFaqLink } from '../FaqLink';

export const getRunningQuestion = (intl: IntlShape, locationName: string) =>
  intl.formatMessage({ defaultMessage: 'Are there running trails available in {locationName}?' }, { locationName });

type RunningAnswerProps = {
  locationName: string;
  runningTrails: ActivityFaq;
  intl: IntlShape;
  languageRegionCode: LanguageRegionCode;
};

export const RunningAnswer = ({ locationName, runningTrails, intl, languageRegionCode }: RunningAnswerProps) => {
  const { count, mostPopular: trail } = runningTrails;
  const displayStarRating = formatData(trail.starRating, 1, null, languageRegionCode);
  const displayReviewCount = formatData(trail.reviewCount, 0, null, languageRegionCode);

  if (count === 1) {
    return intl.formatMessage(
      {
        defaultMessage:
          'According to AllTrails.com, there is 1 running trail in {locationName} which is {trail} with an average {rating} star rating from {reviewCount} community reviews.'
      },
      {
        locationName,
        trail: <TrailFaqLink languageRegionCode={languageRegionCode} trail={trail} />,
        rating: displayStarRating,
        reviewCount: displayReviewCount
      }
    );
  }

  return intl.formatMessage(
    {
      defaultMessage:
        'According to AllTrails.com, there are {count} running trails in {locationName} and the most popular is {trail} with an average {rating} star rating from {reviewCount} community reviews.'
    },
    {
      locationName,
      count,
      trail: <TrailFaqLink languageRegionCode={languageRegionCode} trail={trail} />,
      rating: displayStarRating,
      reviewCount: displayReviewCount
    }
  );
};
