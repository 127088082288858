import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { FormattedMessage } from 'react-intl';
import { humanizeTimeSpan, isToday } from '@alltrails/shared/utils/timeHelpers';

type TrailReviewDateProps = {
  created: string;
  languageRegionCode?: LanguageRegionCode;
  shouldHumanize?: boolean;
};

const TrailReviewDate = ({ created, languageRegionCode, shouldHumanize }: TrailReviewDateProps) => {
  if (shouldHumanize) {
    return humanizeTimeSpan(created, languageRegionCode);
  } else {
    const reviewDate = new Date(created);
    const yesterdayDate = new Date();
    yesterdayDate.setDate(new Date().getDate() - 1);
    const yesterdayStart = new Date(yesterdayDate.setHours(0, 0, 0, 0));

    if (reviewDate > yesterdayStart) {
      return isToday(created) ? <FormattedMessage defaultMessage="Today" /> : <FormattedMessage defaultMessage="Yesterday" />;
    } else {
      return reviewDate.toLocaleDateString(languageRegionCode || LanguageRegionCode.English, {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      });
    }
  }
};

export default TrailReviewDate;
