import { ComponentProps, forwardRef, useEffect, useState } from 'react';
import { isTouchDevice } from '@alltrails/core';
import Tooltip from '../Tooltip';
import IconButton from '../IconButton';

const IconButtonWithTooltip = forwardRef(
  (
    {
      className,
      disabled,
      icon,
      linkInfo,
      loading,
      onBlur,
      onClick,
      onFocus,
      size = 'md',
      tabIndex,
      testId,
      title,
      type = 'button',
      variant = 'default',
      tooltipProps = {},
      stopPropagation = false
    }: ComponentProps<typeof IconButton> & {
      tooltipProps?: Partial<ComponentProps<typeof Tooltip>>;
    },
    ref: React.Ref<HTMLElement>
  ) => {
    const [renderTooltip, setRenderTooltip] = useState(true);

    const buttonElement = (
      <IconButton
        className={className}
        disabled={disabled}
        icon={icon}
        linkInfo={linkInfo}
        loading={loading}
        onBlur={onBlur}
        onClick={onClick}
        onFocus={onFocus}
        size={size}
        tabIndex={tabIndex}
        testId={testId}
        title={renderTooltip ? '' : title}
        ariaLabel={title}
        type={type}
        variant={variant}
        stopPropagation={stopPropagation}
        ref={ref as React.Ref<HTMLAnchorElement>}
      />
    );

    useEffect(() => {
      setRenderTooltip(!isTouchDevice());
    }, []);

    if (renderTooltip) {
      return <Tooltip anchorElement={buttonElement} testId={`${testId}-tooltip`} text={title} {...tooltipProps} />;
    }

    return buttonElement;
  }
);

export default IconButtonWithTooltip;
