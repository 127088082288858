export enum Issue {
  FakeProfile = 'Fake profile',
  Spam = 'Spam',
  SpamAccount = 'Spam account',
  HarmfulContent = 'Harmful content',
  HarmfulBehavior = 'Harmful behavior',
  PrivacyIssue = 'Privacy issue',
  Other = 'Other'
}

export enum EntryPoint {
  Maps = 'maps',
  Lists = 'lists',
  Reviews = 'reviews',
  Photos = 'photos',
  User = 'user',
  FeedComment = 'feed comment'
}

export type ReportType = 'profile' | 'issue';

export type Report = { id: Issue; label: string; description?: string };
