import classNames from 'classnames';
import Link from '@alltrails/denali/components/Link';
import styles from './LinksColumn.module.scss';

const LinksColumn = ({ links }) => {
  return (
    <ul>
      {links.map((link, index) => (
        <li
          key={link.label}
          className={classNames(styles.linkItem, {
            [styles.addPadding]: index !== 0,
            [styles.addPaddingTop]: index === 0,
            [styles.addPaddingBottom]: index === links.length - 1
          })}
        >
          <Link href={link.href} size="sm" noUnderline target="_blank" variant="secondary">
            {link.label}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default LinksColumn;
