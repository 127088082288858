import { Typography } from '@alltrails/core';
import LinksColumn from '../../LinksColumn';
import styles from './DesktopLinkSection.module.scss';

type DesktopLinkSectionProps = {
  title: string;
  links: { label: string; href: string }[];
};

const DesktopLinkSection = ({ title, links }: DesktopLinkSectionProps) => {
  const firstBatch = links.slice(0, 8);
  const secondBatch = links.slice(8, 15);

  return (
    <div>
      <Typography variant="heading200" component="div" className={styles.title}>
        {title}
      </Typography>
      <div className={styles.linkBlockColumns}>
        <div className={styles.linksColumn}>
          <LinksColumn links={firstBatch} />
        </div>
        {!!secondBatch.length && (
          <div className={styles.linksColumn}>
            <LinksColumn links={secondBatch} />
          </div>
        )}
      </div>
    </div>
  );
};

export default DesktopLinkSection;
