import { wrapUrlSafe, localizeUrlComponents } from '@alltrails/shared/utils/languageSupport';
import styles from './FaqLink.module.scss';

export const FaqLink = ({ href, title, languageRegionCode }) => {
  return (
    <a href={wrapUrlSafe(href, languageRegionCode)} data-testid={title} className={styles.link}>
      {title}
    </a>
  );
};

export const TrailFaqLink = ({ trail, languageRegionCode }) => {
  const href = localizeUrlComponents(`/trail/${trail.slug}`, languageRegionCode);
  return (
    <a href={wrapUrlSafe(href, languageRegionCode)} data-testid={trail.name} className={styles.link}>
      {trail.name}
    </a>
  );
};
