'use client';

import Accordion from '@alltrails/denali/components/Accordion';
import { Typography } from '@alltrails/core';
import LinksColumn from '../LinksColumn';
import styles from './LinkAccordian.module.scss';

type LinkAccordianProps = {
  title: string | React.ReactNode;
  isOpen?: boolean;
  toggleIsOpen: () => void;
  links: { label: string; href: string }[];
};

const LinkAccordian = ({ title, isOpen = false, toggleIsOpen, links }: LinkAccordianProps) => {
  return (
    <Accordion
      title={
        <Typography component="h3" variant="text200">
          {title}
        </Typography>
      }
      variant="minimal"
      buttonContainerClassName={styles.buttonContainer}
      externalControls={{
        isOpen,
        toggleIsOpen
      }}
    >
      <div className={styles.links}>
        <LinksColumn links={links} />
      </div>
    </Accordion>
  );
};

export default LinkAccordian;
