import { type IntlShape } from 'react-intl';
import { FaqFilterType } from '../types';

export const getParkAggregatesQuestionStrings = (intl: IntlShape, locationName: string, filter?: FaqFilterType) => {
  const messageKey = filter || 'default';

  const translations = {
    default: intl.formatMessage(
      {
        defaultMessage: 'How many trails are in {locationName}?'
      },
      { locationName }
    ),
    backpacking: intl.formatMessage(
      {
        defaultMessage: 'How many backpacking trails are in {locationName}?'
      },
      { locationName }
    ),
    'bike-touring': intl.formatMessage(
      {
        defaultMessage: 'How many bike touring trails are in {locationName}?'
      },
      { locationName }
    ),
    birding: intl.formatMessage(
      {
        defaultMessage: 'How many bird watching trails are in {locationName}?'
      },
      { locationName }
    ),
    camping: intl.formatMessage(
      {
        defaultMessage: 'How many camping trails are in {locationName}?'
      },
      { locationName }
    ),
    fishing: intl.formatMessage(
      {
        defaultMessage: 'How many fishing trails are in {locationName}?'
      },
      { locationName }
    ),
    hiking: intl.formatMessage(
      {
        defaultMessage: 'How many hiking trails are in {locationName}?'
      },
      { locationName }
    ),
    'horseback-riding': intl.formatMessage(
      {
        defaultMessage: 'How many horseback riding trails are in {locationName}?'
      },
      { locationName }
    ),
    'mountain-biking': intl.formatMessage(
      {
        defaultMessage: 'How many mountain biking trails are in {locationName}?'
      },
      { locationName }
    ),
    'off-road-driving': intl.formatMessage(
      {
        defaultMessage: 'How many off road driving trails are in {locationName}?'
      },
      { locationName }
    ),
    'road-biking': intl.formatMessage(
      {
        defaultMessage: 'How many road biking trails are in {locationName}?'
      },
      { locationName }
    ),
    'rock-climbing': intl.formatMessage(
      {
        defaultMessage: 'How many rock climbing trails are in {locationName}?'
      },
      { locationName }
    ),
    'scenic-driving': intl.formatMessage(
      {
        defaultMessage: 'How many scenic driving trails are in {locationName}?'
      },
      { locationName }
    ),
    skiing: intl.formatMessage(
      {
        defaultMessage: 'How many skiing trails are in {locationName}?'
      },
      { locationName }
    ),
    snowshoeing: intl.formatMessage(
      {
        defaultMessage: 'How many snowshoeing trails are in {locationName}?'
      },
      { locationName }
    ),
    'trail-running': intl.formatMessage(
      {
        defaultMessage: 'How many running trails are in {locationName}?'
      },
      { locationName }
    ),
    'via-ferrata': intl.formatMessage(
      {
        defaultMessage: 'How many via ferrata trails are in {locationName}?'
      },
      { locationName }
    ),
    walking: intl.formatMessage(
      {
        defaultMessage: 'How many walking trails are in {locationName}?'
      },
      { locationName }
    ),
    'cross-country-skiing': intl.formatMessage(
      {
        defaultMessage: 'How many cross country skiing trails are in {locationName}?'
      },
      { locationName }
    ),
    ada: intl.formatMessage(
      {
        defaultMessage: 'How many wheelchair friendly trails are in {locationName}?'
      },
      { locationName }
    ),
    beach: intl.formatMessage(
      {
        defaultMessage: 'How many beach trails are in {locationName}?'
      },
      { locationName }
    ),
    cave: intl.formatMessage(
      {
        defaultMessage: 'How many cave trails are in {locationName}?'
      },
      { locationName }
    ),
    'city-walk': intl.formatMessage(
      {
        defaultMessage: 'How many city walk trails are in {locationName}?'
      },
      { locationName }
    ),
    dogs: intl.formatMessage(
      {
        defaultMessage: 'How many dog friendly trails are in {locationName}?'
      },
      { locationName }
    ),
    forest: intl.formatMessage(
      {
        defaultMessage: 'How many forest trails are in {locationName}?'
      },
      { locationName }
    ),
    'historic-site': intl.formatMessage(
      {
        defaultMessage: 'How many historic site trails are in {locationName}?'
      },
      { locationName }
    ),
    'hot-springs': intl.formatMessage(
      {
        defaultMessage: 'How many trails with hot springs are in {locationName}?'
      },
      { locationName }
    ),
    kids: intl.formatMessage(
      {
        defaultMessage: 'How many kid friendly trails are in {locationName}?'
      },
      { locationName }
    ),
    lake: intl.formatMessage(
      {
        defaultMessage: 'How many lake trails are in {locationName}?'
      },
      { locationName }
    ),
    'partially-paved': intl.formatMessage(
      {
        defaultMessage: 'How many partially paved trails are in {locationName}?'
      },
      { locationName }
    ),
    paved: intl.formatMessage(
      {
        defaultMessage: 'How many paved trails are in {locationName}?'
      },
      { locationName }
    ),
    'pub-crawl': intl.formatMessage(
      {
        defaultMessage: 'How many pub walks are in {locationName}?'
      },
      { locationName }
    ),
    'rails-trails': intl.formatMessage(
      {
        defaultMessage: 'How many rails trails are in {locationName}?'
      },
      { locationName }
    ),
    river: intl.formatMessage(
      {
        defaultMessage: 'How many river trails are in {locationName}?'
      },
      { locationName }
    ),
    strollers: intl.formatMessage(
      {
        defaultMessage: 'How many stroller friendly trails are in {locationName}?'
      },
      { locationName }
    ),
    waterfall: intl.formatMessage(
      {
        defaultMessage: 'How many waterfall trails are in {locationName}?'
      },
      { locationName }
    ),
    wildlife: intl.formatMessage(
      {
        defaultMessage: 'How many wildlife trails are in {locationName}?'
      },
      { locationName }
    )
  };

  return translations[messageKey];
};

type ParkAggregatesAnswerWithFilterProps = {
  filter: FaqFilterType;
  locationName: string;
  trailCount: string;
  rating: string;
  reviewCount: string;
  intl: IntlShape;
};
export const ParkAggregatesAnswerWithFilter = ({
  filter,
  locationName,
  trailCount,
  rating,
  reviewCount,
  intl
}: ParkAggregatesAnswerWithFilterProps) => {
  const messageKey = filter || 'default';

  const translations = {
    default: intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} trails in {locationName}. These trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    backpacking: intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} backpacking trails in {locationName}. These backpacking trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    'bike-touring': intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} bike touring trails in {locationName}. These bike touring trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    birding: intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} bird watching trails in {locationName}. These bird watching trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    camping: intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} camping trails in {locationName}. These camping trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    fishing: intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} fishing trails in {locationName}. These fishing trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    hiking: intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} hiking trails in {locationName}. These hiking trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    'horseback-riding': intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} horseback riding trails in {locationName}. These horseback riding trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    'mountain-biking': intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} mountain biking trails in {locationName}. These mountain biking trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    'off-road-driving': intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} off road driving trails in {locationName}. These off road driving trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    'road-biking': intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} road biking trails in {locationName}. These road biking trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    'rock-climbing': intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} rock climbing trails in {locationName}. These rock climbing trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    'scenic-driving': intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} scenic driving trails in {locationName}. These scenic driving trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    skiing: intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} skiing trails in {locationName}. These skiing trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    snowshoeing: intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} snowshoeing trails in {locationName}. These snowshoeing trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    'trail-running': intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} running trails in {locationName}. These running trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    'via-ferrata': intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} via ferrata trails in {locationName}. These via ferrata trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    walking: intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} walking trails in {locationName}. These walking trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    'cross-country-skiing': intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} cross country skiing trails in {locationName}. These cross country skiing trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    ada: intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} wheelchair friendly trails in {locationName}. These wheelchair friendly trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    beach: intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} beach trails in {locationName}. These beach trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    cave: intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} cave trails in {locationName}. These cave trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    'city-walk': intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} city walk trails in {locationName}. These city walk trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    dogs: intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} dog friendly trails in {locationName}. These dog friendly trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    forest: intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} forest trails in {locationName}. These forest trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    'historic-site': intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} historic site trails in {locationName}. These historic site trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    'hot-springs': intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} trails with hot springs in {locationName}. These trails with hot springs have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    kids: intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} kid friendly trails in {locationName}. These kid friendly trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    lake: intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} lake trails in {locationName}. These lake trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    'partially-paved': intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} partially paved trails in {locationName}. These partially paved trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    paved: intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} paved trails in {locationName}. These paved trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    'pub-crawl': intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} pub walks in {locationName}. These pub walks have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    'rails-trails': intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} rails trails in {locationName}. These rails trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    river: intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} river trails in {locationName}. These river trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    strollers: intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} stroller friendly trails in {locationName}. These stroller friendly trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    waterfall: intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} waterfall trails in {locationName}. These waterfall trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    ),
    wildlife: intl.formatMessage(
      {
        defaultMessage:
          'There are {trailCount} wildlife trails in {locationName}. These wildlife trails have an average {rating}-star rating from {reviewCount} AllTrails community reviews.'
      },
      { trailCount, locationName, rating, reviewCount }
    )
  };

  return translations[messageKey];
};
