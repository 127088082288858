import { type IntlShape } from 'react-intl';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { formatData } from '@alltrails/filters/utils/mapStats';
import { ActivityFaq } from '../../../types/locationDetailsPages/shared';
import { TrailFaqLink } from '../FaqLink';

export const getCampingQuestion = (intl: IntlShape, locationName: string) =>
  intl.formatMessage({ defaultMessage: 'Are there camping trails available in {locationName}?' }, { locationName });

type CampingAnswerProps = {
  locationName: string;
  campingTrails: ActivityFaq;
  languageRegionCode: LanguageRegionCode;
  intl: IntlShape;
};

export const CampingAnswer = ({ locationName, campingTrails, languageRegionCode, intl }: CampingAnswerProps) => {
  const { count, mostPopular: trail } = campingTrails;
  const displayStarRating = formatData(trail.starRating, 1, null, languageRegionCode);
  const displayReviewCount = formatData(trail.reviewCount, 0, null, languageRegionCode);

  if (count === 1) {
    return intl.formatMessage(
      {
        defaultMessage:
          'According to AllTrails.com, there is 1 camping trail in {name} which is {trail} with an average {rating} star rating from {reviewCount} community reviews.'
      },
      {
        name: locationName,
        trail: <TrailFaqLink trail={trail} languageRegionCode={languageRegionCode} />,
        rating: displayStarRating,
        reviewCount: displayReviewCount
      }
    );
  }

  return intl.formatMessage(
    {
      defaultMessage:
        'According to AllTrails.com, there are {count} camping trails in {name} and the most popular is {trail} with an average {rating} star rating from {reviewCount} community reviews.'
    },
    {
      name: locationName,
      count,
      trail: <TrailFaqLink trail={trail} languageRegionCode={languageRegionCode} />,
      rating: displayStarRating,
      reviewCount: displayReviewCount
    }
  );
};
