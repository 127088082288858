'use client';

import { FormattedMessage } from 'react-intl';
import { Typography } from '@alltrails/core';
import Link from '@alltrails/denali/components/Link';

export type LocalizationButtonProps = {
  showSource: boolean;
  toggleShowSource: () => void;
  className?: string;
};

const LocalizationButton = ({ showSource, toggleShowSource, className }: LocalizationButtonProps): JSX.Element => {
  return (
    <Typography className={className} color="secondary" component="div" variant="text200">
      {showSource ? (
        <FormattedMessage defaultMessage="This text is shown in its original language." />
      ) : (
        <FormattedMessage defaultMessage="This text has been translated." />
      )}{' '}
      <Link
        onClick={() => {
          toggleShowSource();
        }}
        size="md"
        variant="secondary"
      >
        {showSource ? <FormattedMessage defaultMessage="Translate" /> : <FormattedMessage defaultMessage="Show original" />}
      </Link>
    </Typography>
  );
};

export default LocalizationButton;
