import { defineMessage, type IntlShape, type MessageDescriptor } from 'react-intl';
import Obstacle from '@alltrails/shared/types/obstacle';

const messages: Record<Obstacle, MessageDescriptor> = {
  blowdown: defineMessage({ defaultMessage: 'Blowdown' }),
  'bridge-out': defineMessage({ defaultMessage: 'Bridge out' }),
  bugs: defineMessage({ defaultMessage: 'Bugs' }),
  closed: defineMessage({ defaultMessage: 'Closed' }),
  fee: defineMessage({ defaultMessage: 'Fee' }),
  flooded: defineMessage({ defaultMessage: 'Flooded' }),
  great: defineMessage({ defaultMessage: 'Great!' }),
  icy: defineMessage({ defaultMessage: 'Icy' }),
  muddy: defineMessage({ defaultMessage: 'Muddy' }),
  'no-shade': defineMessage({ defaultMessage: 'No shade' }),
  'off-trail': defineMessage({ defaultMessage: 'Off-trail (bushwhack)' }),
  'over-grown': defineMessage({ defaultMessage: 'Overgrown' }),
  'private-property': defineMessage({ defaultMessage: 'Private property' }),
  rocky: defineMessage({ defaultMessage: 'Rocky' }),
  scramble: defineMessage({ defaultMessage: 'Scramble' }),
  slippery: defineMessage({ defaultMessage: 'Slippery' }),
  snow: defineMessage({ defaultMessage: 'Snow' }),
  'washed-out': defineMessage({ defaultMessage: 'Washed out' }),
  'poor-air': defineMessage({ defaultMessage: 'Poor air quality' }),
  trash: defineMessage({ defaultMessage: 'Trash on trail' }),
  'poison-plants': defineMessage({ defaultMessage: 'Poisonous plants' }),
  dusty: defineMessage({ defaultMessage: 'Dusty' }),
  pollen: defineMessage({ defaultMessage: 'Pollen' }),
  obstructions: defineMessage({ defaultMessage: 'Obstructions' })
};

const getObstacle = (intl: IntlShape, obstacle: Obstacle) =>
  Object.keys(messages).includes(obstacle) ? intl.formatMessage(messages[obstacle]) : '--';

const allObstacles = (intl: IntlShape) => [
  { id: 'great', text: intl.formatMessage({ defaultMessage: 'Great!' }) },
  { id: 'blowdown', text: intl.formatMessage({ defaultMessage: 'Blowdown' }) },
  { id: 'bridge-out', text: intl.formatMessage({ defaultMessage: 'Bridge out' }) },
  { id: 'bugs', text: intl.formatMessage({ defaultMessage: 'Bugs' }) },
  { id: 'closed', text: intl.formatMessage({ defaultMessage: 'Closed' }) },
  { id: 'fee', text: intl.formatMessage({ defaultMessage: 'Fee' }) },
  { id: 'flooded', text: intl.formatMessage({ defaultMessage: 'Flooded' }) },
  { id: 'icy', text: intl.formatMessage({ defaultMessage: 'Icy' }) },
  { id: 'muddy', text: intl.formatMessage({ defaultMessage: 'Muddy' }) },
  { id: 'no-shade', text: intl.formatMessage({ defaultMessage: 'No shade' }) },
  { id: 'off-trail', text: intl.formatMessage({ defaultMessage: 'Off-trail (bushwhack)' }) },
  { id: 'over-grown', text: intl.formatMessage({ defaultMessage: 'Overgrown' }) },
  { id: 'private-property', text: intl.formatMessage({ defaultMessage: 'Private property' }) },
  { id: 'rocky', text: intl.formatMessage({ defaultMessage: 'Rocky' }) },
  { id: 'scramble', text: intl.formatMessage({ defaultMessage: 'Scramble' }) },
  { id: 'snow', text: intl.formatMessage({ defaultMessage: 'Snow' }) },
  { id: 'washed-out', text: intl.formatMessage({ defaultMessage: 'Washed out' }) }
];

export { getObstacle, allObstacles };
