import { defineMessages } from '@alltrails/shared/react-intl';
import TrailDifficulty from '@alltrails/shared/types/trailDifficulty';
import Features from '@alltrails/filters/constants/featuresEnum';

enum Length {
  Short = 'short',
  Long = 'long'
}

const messages = defineMessages<Features | TrailDifficulty | Length>({
  'off-trail': {
    defaultMessage: 'Off trail'
  },
  'no-shade': {
    defaultMessage: 'No shade'
  },
  'private-property': {
    defaultMessage: 'Private property'
  },
  fee: {
    defaultMessage: 'Fee'
  },
  scramble: {
    defaultMessage: 'Scramble'
  },
  closed: {
    defaultMessage: 'Closed'
  },
  'over-grown': {
    defaultMessage: 'Over grown'
  },
  snow: {
    defaultMessage: 'Snow'
  },
  washed_out: {
    defaultMessage: 'Washed out'
  },
  rocky: {
    defaultMessage: 'Rocky'
  },
  muddy: {
    defaultMessage: 'Muddy'
  },
  strollers: {
    defaultMessage: 'Stroller friendly'
  },
  'stroller-friendly': {
    defaultMessage: 'Stroller friendly'
  },
  blowdown: {
    defaultMessage: 'Blowdown'
  },
  'bridge-out': {
    defaultMessage: 'Bridge out'
  },
  bugs: {
    defaultMessage: 'Bugs'
  },
  'dogs-no': {
    defaultMessage: 'No dogs'
  },
  ada: {
    defaultMessage: 'Wheelchair friendly'
  },
  'wheelchair-friendly': {
    defaultMessage: 'Wheelchair friendly'
  },
  beach: {
    defaultMessage: 'Beach'
  },
  dogs: {
    defaultMessage: 'Dog friendly'
  },
  'dogs-leash': {
    defaultMessage: 'Dogs on leash'
  },
  views: {
    defaultMessage: 'Views'
  },
  wildlife: {
    defaultMessage: 'Wildlife'
  },
  'wild-flowers': {
    defaultMessage: 'Wildflowers'
  },
  forest: {
    defaultMessage: 'Forest'
  },
  event: {
    defaultMessage: 'Event'
  },
  'hot-springs': {
    defaultMessage: 'Hot springs'
  },
  cave: {
    defaultMessage: 'Cave'
  },
  waterfall: {
    defaultMessage: 'Waterfall'
  },
  'rails-trails': {
    defaultMessage: 'Rails trails'
  },
  river: {
    defaultMessage: 'River'
  },
  lake: {
    defaultMessage: 'Lake'
  },
  'city-walk': {
    defaultMessage: 'City walk'
  },
  'historic-site': {
    defaultMessage: 'Historic site'
  },
  'pub-crawl': {
    defaultMessage: 'Pub walk' // verified 2022-06-13 pub-crawl is "Pub walk"
  },
  kids: {
    defaultMessage: 'Kid friendly'
  },
  paved: {
    defaultMessage: 'Paved'
  },
  'partially-paved': {
    defaultMessage: 'Partially paved'
  },
  // Difficulty
  easy: {
    defaultMessage: 'Easy'
  },
  moderate: {
    defaultMessage: 'Moderately challenging'
  },
  hard: {
    defaultMessage: 'Challenging'
  },
  unknown: {
    defaultMessage: 'Unknown'
  },
  // Length
  short: {
    defaultMessage: 'Short'
  },
  long: {
    defaultMessage: 'Long'
  }
});

export default messages;
