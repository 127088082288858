export const metersToFeet = (meters: number) => meters * 3.28084;
export const metersToMiles = (meters: number) => meters * 0.000621371;
export const metersToKilometers = (meters: number) => meters / 1000;
export const feetToMeters = (feet: number) => feet * 0.3048;
export const milesToMeters = (miles: number) => miles * 1609.344;

/**
 * Converts meters to km or mi
 * @param meters Meters value
 * @param metric Use km instead of mi
 * @returns Converted distance
 */
export const getConvertedDistance = (meters: number, metric: boolean) => (metric ? metersToKilometers(meters) : metersToMiles(meters));

/**
 * Converts meters to ft, if needed
 * @param meters Meters value
 * @param metric Leave as m instead of converting to ft
 * @returns Converted elevation
 */
export const getConvertedElevation = (meters: number, metric: boolean) => (metric ? meters : metersToFeet(meters));
