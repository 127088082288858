import Icon from '../components/Icon';

const CollParks = Icon(
  'coll-parks',
  <>
    <path
      fillRule="evenodd"
      d="M19 4.125h3.416a1.58 1.58 0 0 1 1.572 1.767C23.515 9.741 21.407 18.614 12 24 2.593 18.614.485 9.741.012 5.892a1.58 1.58 0 0 1 1.572-1.767H5L4.192 2.2A1.585 1.585 0 0 1 5.65 0h12.7a1.585 1.585 0 0 1 1.458 2.2zm-3.533 5.65c-.918 0-1.981-.99-1.981-.99 0 1.74 1.19 2.632 2.175 3.37.697.522 1.291.967 1.291 1.582 0 .553-.49.99-.99.99-.577 0-1.186-.351-1.7-.723a.618.618 0 0 0-.976.505v3.686h-2.572v-3.686c0-.503-.57-.8-.977-.505-.513.372-1.122.724-1.699.724-.5 0-.99-.438-.99-.99 0-.616.594-1.061 1.291-1.583.986-.738 2.175-1.63 2.175-3.37 0 0-1.062.99-1.98.99-.496 0-.991-.527-.991-.99 0-.527.617-1.028 1.37-1.64.895-.726 1.98-1.607 2.452-2.872a.678.678 0 0 1 1.27 0c.472 1.265 1.558 2.146 2.452 2.872.753.612 1.37 1.113 1.37 1.64 0 .463-.495.99-.99.99"
      clipRule="evenodd"
    />
  </>
);

export default CollParks;
