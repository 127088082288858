import { useEffect, useState } from 'react';
import ShimmerComponents from '@alltrails/shared/components/ShimmerComponents';
import ShimmerWrapper from '@alltrails/shared/components/ShimmerWrapper';
import Link from '@alltrails/denali/components/Link';
import { LinkInfo } from '@alltrails/denali/types';
import { PhotoSize } from '@alltrails/shared/types/photos';
import useCardPhotos from '../../hooks/useCardPhotos';
import styles from './TrailAvatar.module.scss';

const { TrailPhotoShimmer } = ShimmerComponents;

export type Props = {
  baseUrl?: string;
  linkInfo: LinkInfo;
  trailId: number;
  trailName: string;
};

const TrailAvatar = ({ baseUrl, linkInfo, trailId, trailName }: Props): JSX.Element => {
  const [src, setSrc] = useState('');
  const { photos } = useCardPhotos('trails', trailId, PhotoSize.SmallSquare, 1, baseUrl);

  useEffect(() => {
    setSrc(photos?.[0]);
  }, [photos]);

  return (
    <Link {...linkInfo}>
      {src === '' ? (
        <ShimmerWrapper>
          <TrailPhotoShimmer />
        </ShimmerWrapper>
      ) : (
        <img className={styles.avatarPhoto} onError={() => setSrc('')} src={src} alt={trailName} loading="lazy" />
      )}
    </Link>
  );
};

export default TrailAvatar;
