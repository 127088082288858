import classNames from 'classnames';
import { defineMessages } from 'react-intl';
import useFormatMessage from '@alltrails/shared/hooks/useFormatMessage';
import Select from '@alltrails/denali/components/Select';
import type { SortOption } from '../../types';
import styles from './ReviewsSort.module.scss';

const messages = defineMessages({
  SORT_BY: { defaultMessage: 'Sort by' },
  AT_SORT: { defaultMessage: 'AllTrails sort' },
  DESC: { defaultMessage: 'Newest first' },
  ASC: { defaultMessage: 'Oldest first' },
  HIGHEST_RATED: { defaultMessage: 'Highest rated' },
  LOWEST_RATED: { defaultMessage: 'Lowest rated' }
});

export type ReviewsSortProps = {
  sortOption: SortOption;
  onSortChanged: (newSort: SortOption) => void;
  sticky?: boolean;
};

const ReviewsSort = ({ sortOption, onSortChanged, sticky }: ReviewsSortProps): JSX.Element => {
  const {
    formattedDefaultMessages: { SORT_BY, AT_SORT, DESC, ASC, HIGHEST_RATED, LOWEST_RATED }
  } = useFormatMessage(messages);

  const options = {
    atSort: AT_SORT,
    desc: DESC,
    asc: ASC,
    highestRated: HIGHEST_RATED,
    lowestRated: LOWEST_RATED
  };

  const sortOptions = Object.keys(options).map(key => ({ label: options[key], value: key }));
  return (
    <div className={classNames(styles.labelAndSelect, { [styles.sticky]: sticky })}>
      <div className={styles.label}>{SORT_BY}:</div>
      <Select
        className={styles.select}
        options={sortOptions}
        placeholder={SORT_BY}
        value={sortOption as string}
        onChange={value => onSortChanged(value as SortOption)}
        labelText={options.atSort}
        hideLabel
        testId="reviewsSort"
        size="sm"
      />
    </div>
  );
};

export default ReviewsSort;
