import Icon from '../components/Icon';

const First = Icon(
  'first',
  <>
    <path d="M13.125 8.25a.75.75 0 0 0-1.166-.624l-2.25 1.5a.75.75 0 1 0 .832 1.248l1.084-.723v7.599a.75.75 0 0 0 1.5 0z" />
    <path
      fillRule="evenodd"
      d="M3.44 4.19a1.5 1.5 0 0 1 1.06-.44h15a1.5 1.5 0 0 1 1.5 1.5v5.25c0 4.767-2.2 7.78-4.4 9.58a13.8 13.8 0 0 1-3 1.86c-.453.206-.921.402-1.402.533a.76.76 0 0 1-.396 0 9.5 9.5 0 0 1-1.401-.532 13.8 13.8 0 0 1-3-1.86C5.198 18.28 3 15.267 3 10.5V5.25c0-.398.158-.78.44-1.06m12.21 14.73a12.3 12.3 0 0 1-2.671 1.655c-.433.197-.777.322-.979.39a10 10 0 0 1-.979-.39A12.3 12.3 0 0 1 8.35 18.92C6.426 17.345 4.5 14.732 4.5 10.5V5.25h15v5.25c0 4.233-1.926 6.845-3.85 8.42"
      clipRule="evenodd"
    />
  </>
);

export default First;
