'use client';

import { useIntl } from 'react-intl';
import getActivityType from '@alltrails/shared/utils/getActivityType';
import { ActivityType as ActivityTag } from '@alltrails/shared/types/activity';
import { useLanguageRegionCode } from '@alltrails/language';
import Access from '@alltrails/filters/constants/accessEnum';
import Activities from '@alltrails/filters/constants/activitiesEnum';
import TrailFeature from '@alltrails/shared/types/trailFeature';
import { ReactNode, useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import { Tag } from '../../types/locationDetailsPages/shared';
import LinkAccordian from '../LinkAccordian';
import LinksColumn from '../LinksColumn';
import { TagsSection, getFeatureOrAccessLabel, typeToLabel } from './TagsComponents';
import { PointOfInterestTag, SectionType, Section } from './types';
import styles from './Tags.module.scss';

type Props = {
  slug: string;
  tags: Tag[];
  pointsOfInterest?: PointOfInterestTag[];
  isBot?: boolean;
};

export default function Tags({ slug, tags, pointsOfInterest, isBot }: Props) {
  const intl = useIntl();
  const languageRegionCode = useLanguageRegionCode();
  const [openAccordions, setOpenAccordions] = useState<boolean[]>(Array.from({ length: 4 }, _ => false));
  const activityTags = [] as Activities[];
  const attractionTags = [] as TrailFeature[];
  const allSuitabilityTags = [] as Access[];

  const toggleAccordion = useCallback(
    (index: number) => {
      const newAccordions = [...openAccordions];
      newAccordions[index] = !newAccordions[index];
      setOpenAccordions(newAccordions);
    },
    [openAccordions]
  );

  tags.forEach(tag => {
    if (Object.values(Activities).includes(tag as Activities)) {
      activityTags.push(tag as Activities);
    } else if (Object.values(Access).includes(tag as Access)) {
      allSuitabilityTags.push(tag as Access);
    } else {
      attractionTags.push(tag as TrailFeature);
    }
  });

  const collator = new Intl.Collator(languageRegionCode);

  const activities = activityTags
    .map(tag => ({
      label: getActivityType(intl, tag as ActivityTag),
      tag
    }))
    .sort((a, b) => collator.compare(a.label, b.label));

  const attractions = attractionTags
    .map(tag => ({ label: getFeatureOrAccessLabel(intl, tag), tag }))
    .sort((a, b) => collator.compare(a.label, b.label));
  const suitability = allSuitabilityTags
    .map(tag => ({ label: getFeatureOrAccessLabel(intl, tag), tag }))
    .sort((a, b) => collator.compare(a.label, b.label));

  const mapEntries = useCallback(
    section => {
      return section.type === 'poi'
        ? section.entries.map(poi => ({ label: poi.name, href: `/poi/${poi.slug}` }))
        : section.entries.map(tag => ({ label: tag.label, href: `/${slug}/${tag.tag}` }));
    },
    [slug]
  );

  const renderColumns = useCallback(
    (sections: Section[], slug: string) => {
      const mobileColumns: ReactNode[] = [];
      const columns: ReactNode[] = [];
      let remainingColumns = 6;

      sections.forEach((section, index) => {
        mobileColumns.push(
          <LinkAccordian
            key={section.type}
            title={typeToLabel[section.type]}
            isOpen={openAccordions[index]}
            toggleIsOpen={() => toggleAccordion(index)}
            links={mapEntries(section)}
          />
        );
      });

      sections.forEach(section => {
        if (remainingColumns <= 0 || !section.entries.length) return;
        const maxTagsPerColumn = isBot ? 50 : 10; // for bots, show all tags under one title
        const maxTagsTotal = 20;
        const sliceMax = Math.min(maxTagsTotal, section.entries.length);
        const mappedLinks = mapEntries(section);
        const [firstBatch, secondBatch] = [mappedLinks.slice(0, maxTagsPerColumn), mappedLinks.slice(maxTagsPerColumn, sliceMax)];

        columns.push(
          <div className={styles.section} key={`${section.type}-1`}>
            <TagsSection heading={typeToLabel[section.type]}>
              <LinksColumn links={firstBatch} />
            </TagsSection>
          </div>
        );
        remainingColumns--;

        if (secondBatch.length > 0 && remainingColumns > 0) {
          columns.push(
            <div className={styles.section} key={`${section.type}-2`}>
              <TagsSection heading={typeToLabel[section.type]} shouldHideTitle>
                <LinksColumn links={secondBatch} />
              </TagsSection>
            </div>
          );
          remainingColumns--;
        }
      });

      return isBot ? (
        <div className={classNames(styles.content, styles.botContent)}>{columns}</div>
      ) : (
        <>
          <div className={styles.mobileContent}>{mobileColumns}</div>
          <div className={classNames(styles.content, styles.desktopContent)}>{columns}</div>
        </>
      );
    },
    [isBot, openAccordions, toggleAccordion, mapEntries]
  );

  const renderedContent = useMemo(() => {
    return renderColumns(
      [
        ...(activities.length ? [{ entries: activities, type: 'activity' as SectionType }] : []),
        ...(attractions.length ? [{ entries: attractions, type: 'attraction' as SectionType }] : []),
        ...(suitability.length ? [{ entries: suitability, type: 'suitability' as SectionType }] : []),
        ...(pointsOfInterest && pointsOfInterest.length ? [{ entries: pointsOfInterest, type: 'poi' as SectionType }] : [])
      ],
      slug
    );
  }, [renderColumns, activities, attractions, suitability, pointsOfInterest, slug]);

  return <div className={styles.container}>{renderedContent}</div>;
}
