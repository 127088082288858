import type ActivityType from '@alltrails/shared/types/activityType';
import type Review from '@alltrails/shared/types/review';
import type TrailId from '@alltrails/shared/types/trailId';
import { baseApi } from '@alltrails/redux-helpers';
import ReviewPhoto from '@alltrails/shared/types/ReviewPhoto';
import type { RatingsBreakdown, SortOption } from '../types';

type Meta = { items: number; status: string; timestamp: string };

type PageInfo = { hasNextPage: boolean; itemCount: number; nextCursor?: string };

export type ReviewsApiResponse = {
  exclusiveMaximum?: number;
  facets?: { activity: { name: string; uid: ActivityType }[] };
  meta: Meta;
  pageInfo: PageInfo;
  ratings_breakdown?: RatingsBreakdown;
  trail_reviews: Review[];
};

/**
 * Params used exclusively by /api/alltrails/trails/:trailId/reviews and /api/alltrails/users/:userId/reviews
 */
type ReviewsParams = {
  includeRatingsBreakdown?: boolean;
};

/**
 * Params used exclusively by /api/alltrails/trails/:trailId/reviews/search
 */
type SearchParams = {
  after?: string;
  activity?: ActivityType[];
  includeActivityFacets?: boolean;
  month?: number[];
  limit?: number;
  photosOnly?: boolean;
  query?: string;
  rating?: number[];
  trailId: TrailId;
};

/**
 * Params used exclusively by /api/alltrails/land_management/dashboard/reviews
 */
type DashboardParams = {
  startDate?: string;
  endDate?: string;
  areaIds?: number[];
  trailIds?: number[];
};

type ReviewsApiParams = ReviewsParams &
  DashboardParams & {
    /**
     * Defines which endpoint reviews are fetched from (ie. `/api/alltrails/trails/:trailId/reviews`, `/api/alltrails/users/:userId/reviews`, or `/api/alltrails/land_management/dashboard/reviews`)
     */
    baseUrl: string;
    page?: number;
    perPage?: number;
    sortOption?: SortOption;
  };

type CreateTrailReviewParams = { trailId: TrailId; params: any };

export const reviewsApi = baseApi.enhanceEndpoints({ addTagTypes: ['Review'] }).injectEndpoints({
  endpoints: builder => ({
    getReviews: builder.query<ReviewsApiResponse, ReviewsApiParams>({
      query: ({ baseUrl, page, perPage, sortOption, includeRatingsBreakdown, startDate, endDate, areaIds, trailIds }) => {
        const url = `${baseUrl}/reviews`;
        const isDashboard = !!startDate && !!endDate;
        const config = {
          params: {
            page,
            per_page: perPage,
            sort_option: sortOption,
            ...(isDashboard ? { startDate, endDate, areaIds, trailIds } : { include_ratings_breakdown: includeRatingsBreakdown })
          }
        };
        return { url, config, method: 'get' };
      },
      serializeQueryArgs: ({ queryArgs }) => {
        // avoid using page number as a cache key
        const { page, ...rest } = queryArgs;
        return { ...rest };
      },
      merge: (currentCache, results) => {
        currentCache.trail_reviews.push(...results.trail_reviews);
        currentCache.meta = { ...results.meta };
        currentCache.pageInfo = { ...results.pageInfo };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        if (!(currentArg?.page && previousArg?.page)) return true;
        return currentArg.page > previousArg.page;
      },
      providesTags: result => [
        ...(result?.trail_reviews || []).map(({ id }) => ({ type: 'Review' as const, id })),
        { type: 'Review' as const, id: 'placeholder-tag' }
      ]
    }),
    searchReviews: builder.query<ReviewsApiResponse, SearchParams>({
      query: ({ trailId, after, query, rating, activity, month, photosOnly, limit, includeActivityFacets }) => {
        const url = `/api/alltrails/v2/trails/${trailId}/reviews/search`;
        const config = {
          params: {
            after,
            limit,
            query,
            rating,
            activity,
            month,
            photosOnly,
            includeActivityFacets
          }
        };
        return { url, config, method: 'post' };
      },
      serializeQueryArgs: ({ queryArgs }) => {
        // avoid using after cursor as a cache key
        const { after, ...rest } = queryArgs;
        return { ...rest };
      },
      merge: (currentCache, results) => {
        currentCache.trail_reviews.push(...results.trail_reviews);
        currentCache.meta = { ...results.meta };
        currentCache.pageInfo = { ...results.pageInfo };
      },
      forceRefetch: ({ currentArg, previousArg }) => {
        if (!(currentArg && previousArg)) return true;
        if (!currentArg.after && !!previousArg.after) return false;
        return currentArg.after !== previousArg.after;
      },
      providesTags: result => [
        ...(result?.trail_reviews || []).map(({ id }) => ({ type: 'Review' as const, id })),
        { type: 'Review' as const, id: 'placeholder-tag' }
      ]
    }),
    createReview: builder.mutation<ReviewsApiResponse, CreateTrailReviewParams>({
      query: ({ trailId, params }) => ({
        url: `/api/alltrails/trails/${trailId}/reviews`,
        config: { params },
        method: 'post'
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(reviewsApi.util.resetApiState());
        } catch (e) {
          console.error(`[ReviewsAPI] Failed to create review: ${e}`);
        }
      }
    }),
    updateReview: builder.mutation<ReviewsApiResponse, { userId: number; reviewId: number; params: any; photos?: ReviewPhoto[] }>({
      query: ({ userId, reviewId, params }) => ({
        url: `/api/alltrails/users/${userId}/reviews/${reviewId}`,
        data: params,
        method: 'put'
      }),
      transformResponse: (response: ReviewsApiResponse, meta, { reviewId, photos }) => {
        const updatedTrailReviews = response?.trail_reviews?.map(review => {
          if (review.id === reviewId) {
            return {
              ...review,
              ...(photos && photos.length > 0 ? { photos } : {})
            };
          }
          return review;
        });
        return {
          ...response,
          trail_reviews: updatedTrailReviews
        };
      },
      async onQueryStarted({ reviewId }, { dispatch, getState, queryFulfilled }) {
        try {
          const {
            // eslint-disable-next-line camelcase
            data: { trail_reviews }
          } = await queryFulfilled;
          reviewsApi.util.selectInvalidatedBy(getState(), [{ type: 'Review', id: reviewId }]).forEach(({ endpointName, originalArgs }) => {
            if (endpointName === 'getReviews' || endpointName === 'searchReviews') {
              dispatch(
                reviewsApi.util.updateQueryData(endpointName, originalArgs, (data: ReviewsApiResponse) => {
                  // eslint-disable-next-line camelcase
                  const updatedTrailReviews = data.trail_reviews.map(review => (review.id === reviewId ? trail_reviews[0] : review));
                  return { ...data, trail_reviews: updatedTrailReviews };
                })
              );
            }
          });
        } catch (e) {
          console.error(`[ReviewsAPI] Failed to update reviews: ${e}`);
        }
      }
    }),
    deleteReview: builder.mutation<{ meta: Meta }, { userId; reviewId }>({
      query: ({ userId, reviewId }) => ({
        url: `/api/alltrails/users/${userId}/reviews/${reviewId}`,
        method: 'delete'
      }),
      async onQueryStarted({ reviewId }, { dispatch, getState, queryFulfilled }) {
        try {
          await queryFulfilled;
          reviewsApi.util.selectInvalidatedBy(getState(), [{ type: 'Review', id: reviewId }]).forEach(({ endpointName, originalArgs }) => {
            if (endpointName === 'getReviews' || endpointName === 'searchReviews') {
              dispatch(
                reviewsApi.util.updateQueryData(endpointName, originalArgs, (data: ReviewsApiResponse) => {
                  const updatedTrailReviews = data.trail_reviews.filter(review => review.id !== reviewId);
                  return { ...data, trail_reviews: updatedTrailReviews };
                })
              );
            }
          });
        } catch (e) {
          console.error(`[ReviewsAPI] Failed to delete cached review: ${e}`);
        }
      }
    }),
    createReply: builder.mutation<ReviewsApiResponse, { review: Review; comment: string }>({
      query: ({ review: { id, trailId }, comment }) => ({
        url: `/api/alltrails/trails/${trailId}/reviews/${id}/replies`,
        data: { comment },
        method: 'post'
      }),
      async onQueryStarted({ review: { id } }, { dispatch, getState, queryFulfilled }) {
        try {
          const {
            // eslint-disable-next-line camelcase
            data: { trail_reviews }
          } = await queryFulfilled;
          reviewsApi.util.selectInvalidatedBy(getState(), [{ type: 'Review', id }]).forEach(({ endpointName, originalArgs }) => {
            if (endpointName === 'getReviews' || endpointName === 'searchReviews') {
              dispatch(
                reviewsApi.util.updateQueryData(endpointName, originalArgs, (data: ReviewsApiResponse) => {
                  // eslint-disable-next-line camelcase
                  const updatedTrailReviews = data.trail_reviews.map(review => (review.id === id ? trail_reviews[0] : review));
                  return { ...data, trail_reviews: updatedTrailReviews };
                })
              );
            }
          });
        } catch (e) {
          console.error(`[ReviewsAPI] Failed to update reviews with new reply: ${e}`);
        }
      }
    }),
    updateReply: builder.mutation<ReviewsApiResponse, { review: Review; comment: string }>({
      query: ({ review: { id, trailId, replies }, comment }) => ({
        url: replies && replies?.length > 0 ? `/api/alltrails/trails/${trailId}/reviews/${id}/replies/${replies[0].id}` : '',
        data: { comment },
        method: 'put'
      }),
      async onQueryStarted({ review: { id } }, { dispatch, getState, queryFulfilled }) {
        try {
          const {
            // eslint-disable-next-line camelcase
            data: { trail_reviews }
          } = await queryFulfilled;
          reviewsApi.util.selectInvalidatedBy(getState(), [{ type: 'Review', id }]).forEach(({ endpointName, originalArgs }) => {
            if (endpointName === 'getReviews' || endpointName === 'searchReviews') {
              dispatch(
                reviewsApi.util.updateQueryData(endpointName, originalArgs, (data: ReviewsApiResponse) => {
                  // eslint-disable-next-line camelcase
                  const updatedTrailReviews = data.trail_reviews.map(review => (review.id === id ? trail_reviews[0] : review));
                  return { ...data, trail_reviews: updatedTrailReviews };
                })
              );
            }
          });
        } catch (e) {
          console.error(`[ReviewsAPI] Failed to update reviews with updated reply: ${e}`);
        }
      }
    }),
    deleteReply: builder.mutation<ReviewsApiResponse, { review: Review }>({
      query: ({ review: { id, trailId, replies } }) => ({
        url: replies && replies?.length > 0 ? `/api/alltrails/trails/${trailId}/reviews/${id}/replies/${replies[0].id}` : '',
        method: 'delete'
      }),
      async onQueryStarted({ review: { id } }, { dispatch, getState, queryFulfilled }) {
        try {
          const {
            // eslint-disable-next-line camelcase
            data: { trail_reviews }
          } = await queryFulfilled;
          reviewsApi.util.selectInvalidatedBy(getState(), [{ type: 'Review', id }]).forEach(({ endpointName, originalArgs }) => {
            if (endpointName === 'getReviews' || endpointName === 'searchReviews') {
              dispatch(
                reviewsApi.util.updateQueryData(endpointName, originalArgs, (data: ReviewsApiResponse) => {
                  // eslint-disable-next-line camelcase
                  const updatedTrailReviews = data.trail_reviews.map(review => (review.id === id ? trail_reviews[0] : review));
                  return { ...data, trail_reviews: updatedTrailReviews };
                })
              );
            }
          });
        } catch (e) {
          console.error(`[ReviewsAPI] Failed to updated reviews with deleted reply: ${e}`);
        }
      }
    })
  })
});

export const {
  useGetReviewsQuery,
  useSearchReviewsQuery,
  useLazyGetReviewsQuery,
  useLazySearchReviewsQuery,
  useCreateReviewMutation,
  useUpdateReviewMutation,
  useDeleteReviewMutation,
  useCreateReplyMutation,
  useUpdateReplyMutation,
  useDeleteReplyMutation
} = reviewsApi;
