import { type IntlShape } from 'react-intl';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { ReactNode } from 'react';
import { elevationFormatted } from '@alltrails/filters/utils/mapStats';
import { FaqFilterType } from '../types';
import { FAQTrail, LocationDetailsResponseType } from '../../../types/locationDetailsPages/shared';
import { TrailFaqLink } from '../FaqLink';
import {
  getElevationQuestionStrings,
  ParkElevationAnswerWithFilter,
  LocationElevationAnswerWithFilter1,
  LocationElevationAnswerWithFilter2
} from './ElevationMessages';

export const getElevationQuestion = (intl: IntlShape, locationName: string, locationType?: LocationDetailsResponseType, filter?: FaqFilterType) => {
  if (locationType === 'poi') {
    // POI filter pages do not exist, so no variations of this question are necessary
    return intl.formatMessage({ defaultMessage: 'Which trail has the most elevation gain near {locationName}?' }, { locationName });
  }

  return getElevationQuestionStrings(intl, locationName, filter);
};

type ElevationAnswerProps = {
  filter: FaqFilterType;
  locationName: string;
  mostElevationGainTrails: Array<FAQTrail>;
  languageRegionCode: LanguageRegionCode;
  displayMetric: boolean;
  intl: IntlShape;
};

export const ParkElevationAnswer = ({
  filter,
  locationName,
  mostElevationGainTrails,
  languageRegionCode,
  displayMetric,
  intl
}: ElevationAnswerProps) => {
  const trail1 = mostElevationGainTrails[0];
  const displayTrail1ElevationGain = elevationFormatted(trail1.elevationGain, displayMetric, languageRegionCode);

  const trail2 = mostElevationGainTrails[1];
  const displayTrail2ElevationGain = elevationFormatted(trail2.elevationGain, displayMetric, languageRegionCode);

  const answerSentences = [
    intl.formatMessage(
      { defaultMessage: "If you're looking for elevation gain, {name} has you covered." },
      {
        name: locationName
      }
    ),
    <ParkElevationAnswerWithFilter
      filter={filter}
      trail={<TrailFaqLink languageRegionCode={languageRegionCode} trail={trail1} />}
      elevation={displayTrail1ElevationGain}
      key="park-elevation-answer-2"
      intl={intl}
    />,
    intl.formatMessage(
      { defaultMessage: "The park's runner-up is {trail}, which will get you {elevation} of elevation gain." },
      {
        trail: <TrailFaqLink languageRegionCode={languageRegionCode} trail={trail2} />,
        elevation: displayTrail2ElevationGain
      }
    )
  ] as Array<ReactNode>;

  return <span>{answerSentences.reduce((prev, curr) => [prev, ' ', curr], '')}</span>;
};

export const LocationElevationAnswer = ({
  filter,
  locationName,
  mostElevationGainTrails,
  languageRegionCode,
  displayMetric,
  intl
}: ElevationAnswerProps) => {
  const trail1 = mostElevationGainTrails[0];
  const displayTrail1ElevationGain = elevationFormatted(trail1.elevationGain, displayMetric, languageRegionCode);

  const trail2 = mostElevationGainTrails[1];
  const displayTrail2ElevationGain = elevationFormatted(trail2.elevationGain, displayMetric, languageRegionCode);

  return (
    <>
      <LocationElevationAnswerWithFilter1
        locationName={locationName}
        filter={filter}
        trail={<TrailFaqLink languageRegionCode={languageRegionCode} trail={trail1} />}
        elevation={displayTrail1ElevationGain}
        intl={intl}
      />{' '}
      <LocationElevationAnswerWithFilter2
        filter={filter}
        trail={<TrailFaqLink languageRegionCode={languageRegionCode} trail={trail2} />}
        elevation={displayTrail2ElevationGain}
        intl={intl}
      />
    </>
  );
};
