import { type IntlShape } from 'react-intl';
import { FaqFilterType } from '../types';

export const getElevationQuestionStrings = (intl: IntlShape, locationName: string, filter?: FaqFilterType) => {
  const messageKey = filter || 'default';

  const translations = {
    default: intl.formatMessage({ defaultMessage: 'Which trail has the most elevation gain in {locationName}?' }, { locationName }),
    backpacking: intl.formatMessage({ defaultMessage: 'Which backpacking trail has the most elevation gain in {locationName}?' }, { locationName }),
    'bike-touring': intl.formatMessage(
      { defaultMessage: 'Which bike touring trail has the most elevation gain in {locationName}?' },
      { locationName }
    ),
    birding: intl.formatMessage({ defaultMessage: 'Which bird watching trail has the most elevation gain in {locationName}?' }, { locationName }),
    camping: intl.formatMessage({ defaultMessage: 'Which camping trail has the most elevation gain in {locationName}?' }, { locationName }),
    fishing: intl.formatMessage({ defaultMessage: 'Which fishing trail has the most elevation gain in {locationName}?' }, { locationName }),
    hiking: intl.formatMessage({ defaultMessage: 'Which hiking trail has the most elevation gain in {locationName}?' }, { locationName }),
    'horseback-riding': intl.formatMessage(
      { defaultMessage: 'Which horseback riding trail has the most elevation gain in {locationName}?' },
      { locationName }
    ),
    'mountain-biking': intl.formatMessage(
      { defaultMessage: 'Which mountain biking trail has the most elevation gain in {locationName}?' },
      { locationName }
    ),
    'off-road-driving': intl.formatMessage(
      { defaultMessage: 'Which off road driving trail has the most elevation gain in {locationName}?' },
      { locationName }
    ),
    'road-biking': intl.formatMessage({ defaultMessage: 'Which road biking trail has the most elevation gain in {locationName}?' }, { locationName }),
    'rock-climbing': intl.formatMessage(
      { defaultMessage: 'Which rock climbing trail has the most elevation gain in {locationName}?' },
      { locationName }
    ),
    'scenic-driving': intl.formatMessage(
      { defaultMessage: 'Which scenic driving trail has the most elevation gain in {locationName}?' },
      { locationName }
    ),
    skiing: intl.formatMessage({ defaultMessage: 'Which skiing trail has the most elevation gain in {locationName}?' }, { locationName }),
    snowshoeing: intl.formatMessage({ defaultMessage: 'Which snowshoeing trail has the most elevation gain in {locationName}?' }, { locationName }),
    'trail-running': intl.formatMessage({ defaultMessage: 'Which running trail has the most elevation gain in {locationName}?' }, { locationName }),
    'via-ferrata': intl.formatMessage({ defaultMessage: 'Which via ferrata trail has the most elevation gain in {locationName}?' }, { locationName }),
    walking: intl.formatMessage({ defaultMessage: 'Which walking trail has the most elevation gain in {locationName}?' }, { locationName }),
    'cross-country-skiing': intl.formatMessage(
      { defaultMessage: 'Which cross country skiing trail has the most elevation gain in {locationName}?' },
      { locationName }
    ),
    ada: intl.formatMessage({ defaultMessage: 'Which wheelchair friendly trail has the most elevation gain in {locationName}?' }, { locationName }),
    beach: intl.formatMessage({ defaultMessage: 'Which beach trail has the most elevation gain in {locationName}?' }, { locationName }),
    cave: intl.formatMessage({ defaultMessage: 'Which cave trail has the most elevation gain in {locationName}?' }, { locationName }),
    'city-walk': intl.formatMessage({ defaultMessage: 'Which city walk trail has the most elevation gain in {locationName}?' }, { locationName }),
    dogs: intl.formatMessage({ defaultMessage: 'Which dog friendly trail has the most elevation gain in {locationName}?' }, { locationName }),
    forest: intl.formatMessage({ defaultMessage: 'Which forest trail has the most elevation gain in {locationName}?' }, { locationName }),
    'historic-site': intl.formatMessage(
      { defaultMessage: 'Which historic site trail has the most elevation gain in {locationName}?' },
      { locationName }
    ),
    'hot-springs': intl.formatMessage(
      { defaultMessage: 'Which trail with hot springs has the most elevation gain in {locationName}?' },
      { locationName }
    ),
    kids: intl.formatMessage({ defaultMessage: 'Which kid friendly trail has the most elevation gain in {locationName}?' }, { locationName }),
    lake: intl.formatMessage({ defaultMessage: 'Which lake trail has the most elevation gain in {locationName}?' }, { locationName }),
    'partially-paved': intl.formatMessage(
      { defaultMessage: 'Which partially paved trail has the most elevation gain in {locationName}?' },
      { locationName }
    ),
    paved: intl.formatMessage({ defaultMessage: 'Which paved trail has the most elevation gain in {locationName}?' }, { locationName }),
    'pub-crawl': intl.formatMessage({ defaultMessage: 'Which pub walk has the most elevation gain in {locationName}?' }, { locationName }),
    'rails-trails': intl.formatMessage({ defaultMessage: 'Which rails trail has the most elevation gain in  {locationName}?' }, { locationName }),
    river: intl.formatMessage({ defaultMessage: 'Which river trail has the most elevation gain in {locationName}?' }, { locationName }),
    strollers: intl.formatMessage(
      { defaultMessage: 'Which stroller friendly trail has the most elevation gain in {locationName}?' },
      { locationName }
    ),
    waterfall: intl.formatMessage({ defaultMessage: 'Which waterfall trail has the most elevation gain in {locationName}?' }, { locationName }),
    wildlife: intl.formatMessage({ defaultMessage: 'Which wildlife trail has the most elevation gain in {locationName}?' }, { locationName })
  };

  return translations[messageKey];
};

type ParkElevationAnswerWithFilterProps = {
  filter: FaqFilterType;
  trail: React.ReactNode;
  elevation: string;
  intl: IntlShape;
};
export const ParkElevationAnswerWithFilter = ({ filter, trail, elevation, intl }: ParkElevationAnswerWithFilterProps) => {
  const messageKey = filter || 'default';

  const translations = {
    default: intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    backpacking: intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for backpacking trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    'bike-touring': intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for bike touring trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    birding: intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for bird watching trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    camping: intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for camping trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    fishing: intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for fishing trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    hiking: intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for hiking trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    'horseback-riding': intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for horseback riding trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    'mountain-biking': intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for mountain biking trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    'off-road-driving': intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for off road driving trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    'road-biking': intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for road biking trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    'rock-climbing': intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for rock climbing trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    'scenic-driving': intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for scenic driving trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    skiing: intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for skiing trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    snowshoeing: intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for snowshoeing trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    'trail-running': intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for running trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    'via-ferrata': intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for via ferrata trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    walking: intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for walking trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    'cross-country-skiing': intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for cross country skiing trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    ada: intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for wheelchair friendly trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    beach: intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for beach trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    cave: intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for cave trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    'city-walk': intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for city walk trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    dogs: intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for dog friendly trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    forest: intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for forest trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    'historic-site': intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for historic site trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    'hot-springs': intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for trails with hot springs with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    kids: intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for kid friendly trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    lake: intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for lake trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    'partially-paved': intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for partially paved trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    paved: intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for paved trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    'pub-crawl': intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for pub walks with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    'rails-trails': intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for rails trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    river: intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for river trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    strollers: intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for stroller friendly trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    waterfall: intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for waterfall trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    ),
    wildlife: intl.formatMessage(
      {
        defaultMessage: '{trail} boasts the most elevation gain for wildlife trails with {elevation} of total ascent.'
      },
      { trail, elevation }
    )
  };

  return translations[messageKey];
};

type LocationElevationAnswerWithFilterProps1 = {
  locationName: string;
  filter?: FaqFilterType;
  trail: React.ReactNode;
  elevation: string;
  intl: IntlShape;
};
export const LocationElevationAnswerWithFilter1 = ({ locationName, filter, trail, elevation, intl }: LocationElevationAnswerWithFilterProps1) => {
  const messageKey = filter || 'default';

  const translations = {
    default: intl.formatMessage(
      {
        defaultMessage: '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    backpacking: intl.formatMessage(
      {
        defaultMessage:
          '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the backpacking trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    'bike-touring': intl.formatMessage(
      {
        defaultMessage:
          '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the bike touring trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    birding: intl.formatMessage(
      {
        defaultMessage:
          '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the bird watching trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    camping: intl.formatMessage(
      {
        defaultMessage: '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the camping trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    fishing: intl.formatMessage(
      {
        defaultMessage: '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the fishing trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    hiking: intl.formatMessage(
      {
        defaultMessage: '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the hiking trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    'horseback-riding': intl.formatMessage(
      {
        defaultMessage:
          '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the horseback riding trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    'mountain-biking': intl.formatMessage(
      {
        defaultMessage:
          '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the mountain biking trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    'off-road-driving': intl.formatMessage(
      {
        defaultMessage:
          '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the off road driving trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    'road-biking': intl.formatMessage(
      {
        defaultMessage:
          '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the road biking trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    'rock-climbing': intl.formatMessage(
      {
        defaultMessage:
          '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the rock climbing trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    'scenic-driving': intl.formatMessage(
      {
        defaultMessage:
          '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the scenic driving trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    skiing: intl.formatMessage(
      {
        defaultMessage: '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the skiing trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    snowshoeing: intl.formatMessage(
      {
        defaultMessage:
          '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the snowshoeing trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    'trail-running': intl.formatMessage(
      {
        defaultMessage: '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the running trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    'via-ferrata': intl.formatMessage(
      {
        defaultMessage:
          '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the via ferrata trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    walking: intl.formatMessage(
      {
        defaultMessage: '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the walking trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    'cross-country-skiing': intl.formatMessage(
      {
        defaultMessage:
          '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the cross country skiing trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    ada: intl.formatMessage(
      {
        defaultMessage:
          '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the wheelchair friendly trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    beach: intl.formatMessage(
      {
        defaultMessage: '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the beach trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    cave: intl.formatMessage(
      {
        defaultMessage: '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the cave trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    'city-walk': intl.formatMessage(
      {
        defaultMessage:
          '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the city walk trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    dogs: intl.formatMessage(
      {
        defaultMessage:
          '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the dog friendly trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    forest: intl.formatMessage(
      {
        defaultMessage: '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the forest trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    'historic-site': intl.formatMessage(
      {
        defaultMessage:
          '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the historic site trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    'hot-springs': intl.formatMessage(
      {
        defaultMessage:
          '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the trails with hot springs in the area.'
      },
      { locationName, elevation, trail }
    ),
    kids: intl.formatMessage(
      {
        defaultMessage:
          '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the kid friendly trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    lake: intl.formatMessage(
      {
        defaultMessage: '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the lake trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    'partially-paved': intl.formatMessage(
      {
        defaultMessage:
          '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the partially paved trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    paved: intl.formatMessage(
      {
        defaultMessage: '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the paved trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    'pub-crawl': intl.formatMessage(
      {
        defaultMessage: '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the pub walks in the area.'
      },
      { locationName, elevation, trail }
    ),
    'rails-trails': intl.formatMessage(
      {
        defaultMessage: '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the rails trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    river: intl.formatMessage(
      {
        defaultMessage: '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the river trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    strollers: intl.formatMessage(
      {
        defaultMessage:
          '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the stroller friendly trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    waterfall: intl.formatMessage(
      {
        defaultMessage:
          '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the waterfall trails in the area.'
      },
      { locationName, elevation, trail }
    ),
    wildlife: intl.formatMessage(
      {
        defaultMessage:
          '{locationName}: With an ascent of {elevation}, {trail} has the most elevation gain of all of the wildlife trails in the area.'
      },
      { locationName, elevation, trail }
    )
  };

  return translations[messageKey];
};

type LocationElevationAnswerWithFilterProps2 = {
  filter: FaqFilterType;
  trail: React.ReactNode;
  elevation: string;
  intl: IntlShape;
};
export const LocationElevationAnswerWithFilter2 = ({ filter, trail, elevation, intl }: LocationElevationAnswerWithFilterProps2) => {
  const messageKey = filter || 'default';

  const translations = {
    default: intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent trail is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    backpacking: intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for backpacking trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    'bike-touring': intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for bike touring trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    birding: intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for bird watching trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    camping: intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for camping trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    fishing: intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for fishing trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    hiking: intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for hiking trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    'horseback-riding': intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for horseback riding trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    'mountain-biking': intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for mountain biking trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    'off-road-driving': intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for off road driving trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    'road-biking': intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for road biking trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    'rock-climbing': intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for rock climbing trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    'scenic-driving': intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for scenic driving trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    skiing: intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for skiing trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    snowshoeing: intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for snowshoeing trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    'trail-running': intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for running trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    'via-ferrata': intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for via ferrata trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    walking: intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for walking trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    'cross-country-skiing': intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for cross country skiing trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    ada: intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for wheelchair-friendly trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    beach: intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for beach trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    cave: intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for cave trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    'city-walk': intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for city walk trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    dogs: intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for dog-friendly trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    forest: intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for forest trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    'historic-site': intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for historic site trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    'hot-springs': intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for trails with hot springs is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    kids: intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for kid-friendly trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    lake: intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for lake trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    'partially-paved': intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for partially paved trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    paved: intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for paved trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    'pub-crawl': intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for pub walks is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    'rails-trails': intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for rails trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    river: intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for river trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    strollers: intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for stroller-friendly trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    waterfall: intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for waterfall trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    ),
    wildlife: intl.formatMessage(
      {
        defaultMessage: 'The next highest ascent for wildlife trails is {trail} with {elevation} of elevation gain.'
      },
      { trail, elevation }
    )
  };

  return translations[messageKey];
};
