import { ReactNode } from 'react';
import classNames from 'classnames';
import styles from './SectionHeader.module.scss';

type SectionHeaderProps = {
  title: string | ReactNode;
  className?: string;
};

const SectionHeader = ({ title, className }: SectionHeaderProps) => <h2 className={classNames(styles.title, className)}>{title}</h2>;

export default SectionHeader;
