import { type IntlShape } from 'react-intl';
import { formatData } from '@alltrails/filters/utils/mapStats';
import LanguageRegionCode from '@alltrails/shared/types/LanguageRegionCode';
import { FaqFilterType } from '../types';
import { FAQTrail, LocationDetailsResponseType } from '../../../types/locationDetailsPages/shared';
import { TrailFaqLink } from '../FaqLink';
import { PopularDifficultTrailAnswerWithFilter, getPopularDifficultTrailQuestionString } from './PopularDifficultMessages';

export const getPopularDifficultTrailQuestion = (
  intl: IntlShape,
  locationName: string,
  locationType: LocationDetailsResponseType,
  filter?: FaqFilterType
) => {
  if (locationType === 'poi') {
    // POI filter pages do not exist, so no variations of this question are necessary
    return intl.formatMessage({ defaultMessage: 'What is the most popular and difficult trail near {locationName}?' }, { locationName });
  }

  return getPopularDifficultTrailQuestionString(intl, locationName, filter);
};

type PopularDifficultAnswerProps = {
  filter?: FaqFilterType;
  locationName: string;
  popularDifficultTrail: FAQTrail;
  locationType: LocationDetailsResponseType;
  intl: IntlShape;
  languageRegionCode: LanguageRegionCode;
};

export const PopularDifficultTrailAnswer = ({
  filter,
  locationName,
  popularDifficultTrail,
  locationType,
  intl,
  languageRegionCode
}: PopularDifficultAnswerProps) => {
  const { starRating, reviewCount } = popularDifficultTrail;
  const displayReviewCount = formatData(reviewCount, 0, null, languageRegionCode);
  const displayStarRating = formatData(starRating, 1, null, languageRegionCode);

  if (locationType === 'poi') {
    return intl.formatMessage(
      {
        defaultMessage: 'The most popular and difficult trail near {locationName} is {trail} with a {rating}-star rating from {reviewCount} reviews.'
      },
      {
        locationName,
        trail: <TrailFaqLink languageRegionCode={languageRegionCode} trail={popularDifficultTrail} />,
        rating: starRating,
        reviewCount
      }
    );
  }

  return (
    <PopularDifficultTrailAnswerWithFilter
      filter={filter}
      locationName={locationName}
      trail={<TrailFaqLink languageRegionCode={languageRegionCode} trail={popularDifficultTrail} />}
      rating={displayStarRating}
      reviewCount={displayReviewCount}
      intl={intl}
    />
  );
};
